import axios from 'axios';
import { FIREBASE_BASE_URL } from '../utils';
import { Thread } from '@langchain/langgraph-sdk';

export namespace API {

    export const getUserInfo = (props: { email: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getUserInfoByEmail`, {
            params: {
                email: props.email.toLowerCase()
            }
        })
    }

    export const createUser = (props: { awsId: string, name: string, email: string, role: string }) => {
        return axios.post(`${FIREBASE_BASE_URL}/addPblUserToDB`, {
            role: props.role,
            name: props.name,
            email: props.email.toLowerCase(),
            awsId: props.awsId
        })
    }

    export const saveProjectToLearner = async (props: {learnerEmail: string, guideEmail: string, projectTitle: string, projectDescription: string, projectDeliverable: string}) => {
        const response = await axios.post(
            `${FIREBASE_BASE_URL}/addGuideThemedProjectToUser`,
            {
                learnerEmail: props.learnerEmail.toLowerCase(),
                guideEmail: props.guideEmail.toLowerCase(),
                projectTitle: props.projectTitle,
                projectDescription: props.projectDescription,
                projectDeliverable: props.projectDeliverable
            }
        );
        return response
    }

    export const updateProjectStarted = (props: { learnerEmail: string, projectTitle: string, projectStarted: boolean }) => {
        return axios.post(`${FIREBASE_BASE_URL}/addGuideThemedProjectToUser`, {
            learnerEmail: props.learnerEmail.toLowerCase(),
            projectTitle: props.projectTitle,
            projectStarted: props.projectStarted
        })
    }

    export const getGuideThemedProjects = (props: { learnerEmail: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getGuideThemedProjectsForLearner`, {
            params: {
                learnerEmail: props.learnerEmail.toLowerCase()
            }
        })
    }
    
    export const updateIsGuideTheme = (props: { thread: Thread, isGuideTheme: boolean, guideTitle: string, guideDescription: string, guideDeliverable: string }) => {
        return axios.post(`${FIREBASE_BASE_URL}/addProjectPlanToUser`, {
            threadId: props.thread.thread_id,
            isGuideTheme: props.isGuideTheme,
            guideTitle: props.guideTitle,
            guideDescription: props.guideDescription,
            guideDeliverable: props.guideDeliverable
        })
    }

    export const sendNewUserSlackMessage = async (props: { learnerName: string, learnerEmail: string, teacherEmail: string }) => {
        const slackWebhookUrl = "https://hooks.slack.com/triggers/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";
      
        const teacherName = (await axios.get(`${FIREBASE_BASE_URL}/getUserDetailsFromEmail`, {
          params: {
            userEmail: props.teacherEmail.toLowerCase()
          }
        })).data.name;
      
        const messageParams = {
          learnerName: props.learnerName,
          learnerEmail: props.learnerEmail,
          teacherName: teacherName,
          teacherEmail: props.teacherEmail
        };
      
        return axios.post(`${FIREBASE_BASE_URL}/sendSlackMessage`, {
          slackWebhookUrl,
          messageParams
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        });
      }
}
