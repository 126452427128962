import React from 'react';
import { useOptions } from '../export';
import * as Icons from 'solar-icon-set'
import { Option } from '../state';
import { useAssistant } from '../../assistant/export';
import { useWidget } from '../../widget/export';
import mixpanel from 'mixpanel-browser';

const OptionsWidget: React.FC = () => {
    const { options } = useOptions();
    const assistant = useAssistant()
    const widget = useWidget()

    const handleClick = (option: Option) => {
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Option Click', { name: option.title });
        }
        assistant.thunks.sendMessage(option.title);
        widget.actions.reset();
    };

    return (
        <div className="space-y-4">
            <div className='flex w-full flex-col gap-y-2'>
                {options.map((option: Option) => (
                    <div key={option.title} className="flex flex-row justify-between w-full">
                        <button
                            className='w-full hover:bg-pf-gray-T3 rounded-md justify-between px-2 mr-2'
                            onClick={() => handleClick(option)}
                        >
                            <div className='flex flex-row items-center justify-between w-full'>
                                <p className='responsive-text-minus text-ellipsis py-2 text-black overflow-hidden line-clamp-1'>{option.title}</p>
                                <Icons.ArrowRightUp 
                                    size={
                                        window.innerHeight < 640 ? 12 :
                                        window.innerHeight < 768 ? 14 :
                                        window.innerHeight < 1024 ? 16 :
                                        window.innerHeight < 1280 ? 18 : 20
                                    } 
                                    className="ml-2 responsive-icon" 
                                />
                            </div>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OptionsWidget;