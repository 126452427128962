import React from 'react';
import * as Icons from 'solar-icon-set'
import { useAssistant } from '../../export';
import '../../../../grapes/atoms/button/button.css'
import '../../../../grapes/atoms/input/input.css'
import '../../../../../styles/responsive-text.css'
import mixpanel from 'mixpanel-browser';

interface SuggestionProps {
    suggestion: string;
}

const Suggestion: React.FC<SuggestionProps> = ({ suggestion }) => {
    const assistant = useAssistant();

    const handleClick = () => {
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Suggestion Click', { name: suggestion });
        }
        assistant.thunks.sendMessage(suggestion);
    };

    return (
        <div className="flex flex-row justify-between w-full">
            <button
                className='w-full hover:bg-pf-gray-T3 rounded-md justify-between px-2 mr-2'
                onClick={handleClick}
            >
                <div className='flex flex-row items-center justify-between w-full'>
                    <p className='responsive-text-minus text-ellipsis py-2 text-black overflow-hidden line-clamp-1'>{suggestion}</p>
                    <Icons.ArrowRightUp 
                        size={
                            window.innerHeight < 640 ? 12 :
                            window.innerHeight < 768 ? 14 :
                            window.innerHeight < 1024 ? 16 :
                            window.innerHeight < 1280 ? 18 : 20
                        } 
                        className="ml-2 responsive-icon" 
                    />
                </div>
            </button>
        </div>
    );
};

export default Suggestion;
