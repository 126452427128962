import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
    
import { VoiceThunks } from "./thunks";
import { useMemo } from "react";
import { voiceSlice } from "./slice";

export const useVoice = () => {
    const dispatch = useDispatch();

    const selectVoiceState = (state: RootState) => state.voice;
    const loading = useSelector((state: RootState) => state.voice.loading);
    const data = useSelector((state: RootState) => state.voice.data);
    const error = useSelector((state: RootState) => state.voice.error);

    const actions = useMemo(
        () => bindActionCreators(voiceSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(VoiceThunks, dispatch),
        [dispatch]
    );

    return {
        loading,
        data,
        error,
        actions,
        thunks,
    };
};
