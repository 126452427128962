import axios from "axios";
import { FIREBASE_BASE_URL } from "../utils";
import { Project } from "./state";
import { Thread } from "@langchain/langgraph-sdk";

export namespace API {

    export const getProjectsByEmail = (props: { email: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getThreadIdsFromEmail`, {
            params: {
                userEmail: props.email.toLowerCase()
            }
        })
    }

    export const getProjectByThread = (props: { thread: Thread }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getProjectPlanFromThread`, {
            params: {
                threadId: props.thread.thread_id
            }
        })
    }

    export const updateLastLoggedIn = async (props: { userEmail: string, thread: Thread }) => {
        const response = await axios.post(
            `${FIREBASE_BASE_URL}/updateLastLoggedIn`,
            {
                email: props.userEmail.toLowerCase(),
                threadId: props.thread.thread_id,
            }
        );
        return response
    }

    export const saveProject = async (props: Partial<Project> & { userEmail: string, thread: Thread }) => {
        const response = await axios.post(
            `${FIREBASE_BASE_URL}/addProjectPlanToUser`,
            {
                userEmail: props.userEmail.toLowerCase(),
                threadId: props.thread.thread_id,
                projectTitle: props.title,
                projectDescription: props.description,
                imageUrl: props.image?.url
            }
        );
        return response
    }

}
