import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useUpload } from '../export';

const UploadConfetti: React.FC = () => {
    const { status } = useUpload()
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        if (status === 'saving') {
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 3000); // Hide confetti after 5 seconds
        }
    }, [status]);

    return showConfetti ? <div className='z-[99999]'><Confetti /></div> : null;
};

export default UploadConfetti;
