import React from 'react';
import * as Icons from 'solar-icon-set';

const Sticker: React.FC = () => {

    return (
        <div className={`inline-flex flex-row items-center gap-x-2 my-2 border-4 border-white rounded-full p-4 bg-[#56D18F] shadow-lg -rotate-1`}>
            <Icons.CheckCircle color='white' iconStyle='Bold' size={24} />
            <p className='text-white font-bold mx-8 text-center'>{"Step Finished"}</p>
        </div>
    );
};

export default Sticker;
