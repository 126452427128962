import { GuideLearner } from "../../../types/GuideLearner"

export type UserState = {
    data: {
        id: number,
        name: string,
        // email: string,
        // username: string,
        role: 'Anon' | 'Teacher' | 'Guide' | 'Learner' | 'Parent',
        experimentTypes: string[]
        guides: GuideLearner[] | null,
        learners: GuideLearner[] | null,
        projects: Project[],
    },
    loading: boolean,
    guideThemedProjects: GuideThemedProject[];
}

export type Project = {
    threadId: string,
    projectTitle: string
    imageUrl: string
}

export const initState: UserState = {
    data: {
        id: -1,
        name: '',
        // email: '',
        // username: '',
        role: 'Anon',
        experimentTypes: ['NORMAL_USER'],
        guides: null,
        learners: null,
        projects: [],
    },
    loading: false,
    guideThemedProjects: [],
}

export type Learner = {
    name: string;
    email: string;
    pageId?: string | null;
    isApproved: boolean;
};

// Add this interface to your existing types
export interface GuideThemedProject {
    id: number;
    projectTitle: string;
    projectDescription: string;
    projectDeliverable: string;
    projectStarted: boolean;
    guideName: string;
}
