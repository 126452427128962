import React, { ButtonHTMLAttributes } from 'react';
import './iconButton.css';
import Tooltip from '../tooltip/Tooltip';

type IconButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'rounded' | 'borderless';
type TextPosition = 'left' | 'right';
type IconButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: IconButtonVariant;
  icon: React.ReactNode;
  tooltipLabel?: string;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  text?: string;
  textPosition?: TextPosition;
  size?: IconButtonSize;
  loading?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  variant,
  icon,
  className,
  tooltipLabel,
  tooltipPosition = 'top',
  text,
  textPosition = 'right',
  size = 'md',
  loading = false,
  ...props
}) => {
  const baseClasses = 'icon-button';
  const variantClasses = {
    primary: 'icon-button--primary',
    secondary: 'icon-button--secondary',
    tertiary: 'icon-button--tertiary',
    borderless: 'icon-button--borderless',
    rounded: 'icon-button--rounded',
  };

  const sizeClasses = {
    xs: 'icon-button--xs',
    sm: 'icon-button--sm',
    md: 'icon-button--md',
    lg: 'icon-button--lg',
    xl: 'icon-button--xl',
  };

  const iconSizes = {
    xs: 12,
    sm: 16,
    md: 20,
    lg: 22,
    xl: 24,
  };

  const textSizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  const buttonContent = (
    <button
      className={`${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${text ? 'icon-button--with-text' : ''} ${className || ''}`}
      {...props}
    >
      {loading ? (
        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      ) : (
        <>
          {text && textPosition === 'left' && <span className={`icon-button__text icon-button__text--left ${textSizes[size]}`}>{text}</span>}
          {React.cloneElement(icon as React.ReactElement, { size: iconSizes[size] })}
          {text && textPosition === 'right' && <span className={`icon-button__text icon-button__text--right ${textSizes[size]}`}>{text}</span>}
        </>
      )}
    </button>
  );

  return tooltipLabel ? (
    <Tooltip content={tooltipLabel} position={tooltipPosition}>
      {buttonContent}
    </Tooltip>
  ) : buttonContent;
};

export default IconButton;