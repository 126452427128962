import React, { useCallback, useState } from 'react';
import './image.css';
import PfIcon from '../../../../../assets/images/pf-icon.svg';

type ImageProps = {
    src: string;
    alt: string;
    className?: string;
};

const Image: React.FC<ImageProps> = ({ src, alt, className }) => {
    const [hasError, setHasError] = useState(false);
    const [hoverRotation, setHoverRotation] = useState(0);

    const getRandomRotation = useCallback(() => {
        const rotation = Math.floor(Math.random() * 7) - 3; // Random integer between -3 and 3
        return rotation === 0 ? 1 : rotation; // If it's 0, return 1
    }, []);


    return (
        <div className={`image-container ${className || ''}`} 
        onMouseEnter={() => setHoverRotation(getRandomRotation())}
        onMouseLeave={() => setHoverRotation(0)}
        style={{ transform: `rotate(${hoverRotation}deg)` }}>
            <img
                src={src}
                alt={alt}
                className={`image ${hasError ? 'image-placeholder bg-gray-T3' : ''}`}
                onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // Transparent 1x1 pixel
                    setHasError(true);
                }}
            /> 
            {hasError && (
                <div className="image-placeholder-icon absolute inset-0 flex items-center justify-center pointer-events-none">
                    <img src={PfIcon} alt="PF Icon" className="w-[50px] h-[50px]" />
                </div>
            )}
        </div>
    );
};

export default Image;