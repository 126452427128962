import React from 'react';
import Image from '../../../../grapes/atoms/image/Image';
import { API } from '../../../assistant/api';

interface UploadStickerProps {
    imageKey: string;
}

const UploadSticker: React.FC<UploadStickerProps> = ({ imageKey }) => {
    const [imageUrl, setImageUrl] = React.useState<string>('');

    React.useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                if (imageKey?.length > 0) {
                    const url = await API.getImageURLFromAWS(imageKey);
                    setImageUrl(url.fileUrl);
                }
            } catch (error) {
                logger.error('Error fetching image URL:', error);
            }
        };

        fetchImageUrl();
    }, [imageKey]);

    return (
        <div className='w-full flex justify-end'>
            <div className="upload-sticker w-32 rotate-5">
                <Image
                    src={imageUrl}
                    alt="Upload Sticker"
                    className="sticker-image"
                />
            </div>
        </div>
    );
};

export default UploadSticker;
