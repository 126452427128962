import React, { useEffect, useState } from 'react';
import { usePanel } from '../export';
import ImagePanel from '../../project/images/components/ImagePanel';
import ResourcePanel from '../../project/resources/components/Panel';
import InspirationPanel from '../../project/inspirations/components/Panel';
import SummaryPanel from '../../project/summary/components/SummaryPanel';
import StepsPanel from '../../steps/components/Panel';
import { styles } from '../../../../styling/styles';

export const Panel = () => {
    const { state } = usePanel();
    const [isVisible, setIsVisible] = useState(false);
    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (state !== 'none') {
            setIsVisible(true);
            // Add delay before showing children
            const timer = setTimeout(() => {
                setShowChildren(true);
            }, 300);
            return () => clearTimeout(timer);
        } else {
            setIsVisible(false);
            setShowChildren(false);
        }
    }, [state]);

    const PanelContent = ({ children }: { children?: React.ReactNode }) => (
        <div
            className={`
                h-full z-40
                transition-all duration-300 ease-in-out
                ${isVisible ? 'w-1/2 translate-x-0' : 'w-0 translate-x-full'}
            `}
        >
            {/* Set the width to 150% when you want to add full screen */}
            <div className={`w-[100%] h-full p-2 pt-0 absolute right-0
                ${styles.panel} overflow-y-auto
                ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`
            }>
                {children}
            </div>
        </div>
    );

    switch (state) {
        case "images":
            return (
                <PanelContent>
                    <ImagePanel />
                </PanelContent>
            );
        case "resources":
            return (
                <PanelContent>
                    <ResourcePanel />
                </PanelContent>
            );
        case "inspirations":
            return (
                <PanelContent>
                    <InspirationPanel />
                </PanelContent>
            );
        case "summary":
            return (
                <PanelContent>
                    <SummaryPanel />
                </PanelContent>
            );
        case "steps":
            return (
                <PanelContent>
                    <StepsPanel />
                </PanelContent>
            );
        default:
            return (
                <></>
            );
    }
};