import mixpanel from "mixpanel-browser";
import { Thunk } from "../utils";
import { userSlice } from "./slice";
import { API } from "./api";
import { API as ProjectAPI } from "../project/api"
import { toastSlice } from "../toast/slice";
import { GuideAPI } from "./guide-api";
import { signUp } from "aws-amplify/auth";
import { createAsyncThunk } from '@reduxjs/toolkit';

export namespace UserThunks {
    export const getProjectsByEmailThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Getting projects by email");

        try {
            dispatch(userSlice.actions.setLoading(true));

            const email = getState().auth.data.email

            if (email === "") return;

            const response = await ProjectAPI.getProjectsByEmail({ email: email });

            if (response.status === 200) {
                logger.log("[USER THUNK] ✅ Projects fetched successfully");
                dispatch(userSlice.actions.setProjects(response.data.threadIdsAndTitles));
            } else {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error fetching projects", error);
            logger.error(error)
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch projects",
                error: error.message || "Unknown error occurred",
                fatal: false
            }))
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Get projects by email completed");
        }
    };

    export const getUserInfoThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Getting user info by email");

        try {
            dispatch(userSlice.actions.setLoading(true));

            const email = getState().auth.data.email;

            if (email === "") return;

            const response = await API.getUserInfo({ email });

            if (response.status === 200) {
                logger.log("[USER THUNK] ✅ User info fetched successfully", response.data);
                // After successful login or signup
                if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                    mixpanel.identify(response.data.awsId);
                    mixpanel.people.set({
                        $email: response.data.email,
                        $name: response.data.name,
                        $role: response.data.role,
                        $Guides: response.data.teachers,
                        $Learners: response.data.learners,
                    });
                    mixpanel.track('User Active');
                    mixpanel.track('User Logged In', {
                        $first_login: response.data.lastLoggedIn.length === 0 || false
                    });
                }
                dispatch(userSlice.actions.setUserInfo({
                    name: response.data.name,
                    role: response.data.role,
                    experimentTypes: response.data.experimentTypes || ['NORMAL_USER'],
                    Guides: response.data.teachers || [],
                    Learners: response.data.students || []
                }));
            } else {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error fetching user info", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch user role",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Get user info completed");
        }
    }

    export const createUserThunk: Thunk<{ awsId: string, name: string, email: string, role: string }> = (props) => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Creating user");

        try {
            dispatch(userSlice.actions.setLoading(true));

            API.createUser({
                awsId: props.awsId,
                name: props.name,
                email: props.email,
                role: 'Learner'
            });
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error creating user", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to create user",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Create user completed");
        }
    };

    // Define a new thunk for creating a single learner
    export const createSingleLearnerAccount = createAsyncThunk<
        { email: string; success: boolean; error?: string },
        { guideEmail: string; learner: { name: string; email: string; password: string } }
    >(
        'user/createSingleLearnerAccount',
        async ({ guideEmail, learner }, { rejectWithValue }) => {
            try {
                // Check if name, email and password are present
                if (!learner.name) {
                    throw new Error('Name is required');
                }
                if (!learner.email) {
                    throw new Error('Email is required');
                }
                if (!learner.password) {
                    throw new Error('Password is required');
                }
                if (learner.password.length < 6) {
                    throw new Error('Password needs to be >5 characters long.')
                }
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(learner.email)) {
                    throw new Error('Email not entered correctly')
                }

                // Sign up on AWS
                const response = await signUp({
                    username: learner.email.toLowerCase(),
                    password: learner.password,
                });

                if (response.userId) {
                    // Call addPblUserToDb
                    await API.createUser({
                        awsId: response.userId!,
                        name: learner.name,
                        email: learner.email.toLowerCase(),
                        role: 'Learner'
                    });

                    // Add Learner to Guide
                    await GuideAPI.addLearnerToGuide({
                        guideEmail: guideEmail.toLowerCase(),
                        learnerEmail: learner.email.toLowerCase(),
                    });

                    API.sendNewUserSlackMessage({
                        learnerName: learner.name,
                        learnerEmail: learner.email.toLowerCase(),
                        teacherEmail: guideEmail.toLowerCase()
                    });

                    logger.log(`[USER THUNK] ✅ Learner account created for ${learner.email}`);
                    return { email: learner.email, success: true };
                } else {
                    throw new Error('Failed to create AWS account for Learner');
                }
            } catch (error: any) {
                logger.log(`[USER THUNK] 🔴 Error creating account for Learner ${learner.email}`, error);
                return rejectWithValue({ email: learner.email, success: false, error: error.message || 'Failed to create account' });
            }
        }
    );

    export const getGuideThemedProjectsThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Getting guide-themed projects");
    
        try {
            dispatch(userSlice.actions.setLoading(true));
    
            const email = getState().auth.data.email;
    
            if (email === "") return;
    
            const response = await API.getGuideThemedProjects({ learnerEmail: email });
    
            if (response.status === 200) {
                logger.log("[USER THUNK] ✅ Guide-themed projects fetched successfully");
                dispatch(userSlice.actions.setGuideThemedProjects(response.data.projects));
            } else {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error fetching guide-themed projects", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch guide-themed projects",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Get guide-themed projects completed");
        }
    };
}