import React from 'react';
import Tooltip from '../tooltip/Tooltip';
import Button from '../button/Button';
import '../../../../styles/responsive-text.css';

const ToggleButtons: React.FC<{
    active: boolean;
    onToggle: () => void;
    label: string;
}> = ({ active, onToggle, label }) => (
    <button
        onClick={onToggle}
        className={`px-8 py-2 rounded-md focus:outline-none transition-colors duration-200 ${active
            ? "bg-white"
            : "bg-transparent text-black"
            }`}
    >
        <p className="responsive-text">{label}</p>
    </button>
);

type WordToggleProps = {
    leftSelected: boolean,
    leftLabel: string,
    rightLabel: string
    onLeft: () => void,
    onRight: () => void
}

const WordToggle: React.FC<WordToggleProps> = ({ leftSelected, leftLabel, rightLabel, onLeft, onRight }) => {

    return (
        <div className="flex space-x-2 p-2">
            <Tooltip position="bottom" content={!leftSelected ? "See your Progress Board" : "See your Chat"}>
                <div className="p-1 flex space-x-1 justify-between bg-gray-100 rounded-md">
                    <ToggleButtons
                        active={leftSelected}
                        onToggle={() => {
                            onLeft();
                        }}
                        label={leftLabel}
                    />
                    <ToggleButtons
                        active={!leftSelected}
                        onToggle={() => {
                            onRight();
                        }}
                        label={rightLabel}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default WordToggle;