import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Run } from "../index";
import { initState, Message } from "./state";

export const assistantSlice = createSlice({
    initialState: initState,
    name: "assistant",
    reducers: {
        setLoading(state, action: PayloadAction<"false" | "streaming" | "true">) {
            state.loading = action.payload;
        },

        createMessage(state, action: Action<Message>) {
            return {
                ...state,
                messages: [
                    ...state.messages,
                    {
                        content: {
                            message: action.payload.content.message,
                            ...(action.payload.content.fileUrl ? { fileUrl: action.payload.content.fileUrl } : {}),
                        },
                        role: action.payload.role,
                        ...(action.payload.tool ? { tool: action.payload.tool } : {}),
                    },
                ],
                // focused: state.data.messages.length
            };
        },

        setMessages(state, action: Action<Message[]>) {
            return {
                ...state,
                messages: action.payload,
            };
        },

        streamMessage(state, action: Action<Message>) {
            const lastIndex = state.messages.length - 1;
            return {
                ...state,
                messages: [
                    ...state.messages.slice(0, lastIndex),
                    {
                        ...state.messages[lastIndex],
                        content: {
                            ...state.messages[lastIndex]?.content,
                            message: (state.messages[lastIndex]?.content?.message || "") + (action.payload.content?.message || ""),
                            tool: action.payload.tool
                        },
                        role: action.payload.role,
                        ...(action.payload.tool ? { tool: action.payload.tool } : {}),
                    }
                ],
            }
        },

        setSuggestions(state, action: Action<string[] | null>) {
            return {
                ...state,
                suggestions: action.payload,
            };
        },

        clearSuggestions(state) {
            return {
                ...state,
                suggestions: initState.suggestions,
            };
        },

        setFile(state, action: Action<File | null>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    file: action.payload,
                },
            };
        },

        setFileUrl(state, action: Action<string>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    fileUrl: action.payload,
                },
            };
        },

        setFileStatus(state, action: Action<"none" | "preparing" | "uploading" | "uploaded" | "error">) {
            return {
                ...state,
                fileStatus: action.payload,
            };
        },

        reset(state) {
            return {
                ...initState
            };
        },
    },
});