import React from 'react';
import * as Icons from 'solar-icon-set';
import { usePanel } from '../export';
import Button from '../../../grapes/atoms/button/Button';
import { useWidget } from '../../widget/export';
import { useResources } from '../../project/resources/export';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../../auth/export';

type PanelHeaderProps = {
    onBack?: () => void
    onForward?: () => void
    backEnabled?: boolean,
    forwardEnabled?: boolean,
    closeable?: boolean
}

const PanelHeader = ({ onBack, onForward, backEnabled, forwardEnabled, closeable = true }: PanelHeaderProps) => {
    const auth = useAuth()
    const panel = usePanel()
    const widget = useWidget()
    const resources = useResources()

    return (
        <div className="flex justify-between items-center mb-4">
            <div className='gap-x-2 flex'>
                {onBack &&
                    <button
                        disabled={!backEnabled}
                        onClick={onBack}
                    >
                        <Icons.ArrowLeft size={24} />
                    </button>
                }
                {onForward &&
                    <button
                        disabled={!forwardEnabled}
                        onClick={onForward}
                    >
                        <Icons.ArrowRight size={24} />
                    </button>
                }
            </div>
            {closeable && (
                <button onClick={() => {
                    panel.actions.setState('none')
                    widget.actions.setState('none')
                    resources.actions.setMainResource(null)
                    // Mixpanel tracking for panel closed
                    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                        mixpanel.track('Panel closed', {
                            $email: auth.state.data.email,
                            panel: panel.state
                        });
                    }
                }}>
                    <Icons.CloseCircle iconStyle='Bold' size={24} />
                </button>
            )}
        </div>
    );
};

export default PanelHeader;

