import React, { PropsWithChildren, useEffect, useState, useCallback } from 'react';
import Button from '../button/Button';
import * as Icons from 'solar-icon-set'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './index.css';

type TextCardProps = {
    title: string;
    email: string;
    className?: string;
    onClick?: () => void;
    handleDelete?: (email: string) => void;
    activeProjectTitle: string;
    addedToProject: boolean;
};

const TextCard: React.FC<PropsWithChildren<TextCardProps>> = ({ title, email, className, onClick, handleDelete, activeProjectTitle, addedToProject }) => {
    const [showAddedMessage, setShowAddedMessage] = useState(false);
    const [hoverRotation, setHoverRotation] = useState(0);

    useEffect(() => {
        if (addedToProject) {
            setShowAddedMessage(true);
            const timer = setTimeout(() => {
                setShowAddedMessage(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [addedToProject]);

    const getRandomRotation = useCallback(() => {
        const rotation = Math.floor(Math.random() * 7) - 3; // Random integer between -3 and 3
        return rotation === 0 ? 1 : rotation; // If it's 0, return 1
    }, []);

    return (
        <div
            className={`card ${className} ${onClick ? 'card-clickable' : ''}`}
            onClick={onClick}
            onMouseEnter={() => setHoverRotation(getRandomRotation())}
            onMouseLeave={() => setHoverRotation(0)}
            style={{ transform: `rotate(${hoverRotation}deg)` }}
        >
            {handleDelete && (
                <button
                    className="card-delete-button"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        handleDelete(email);
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} className="card-delete-icon" />
                </button>
            )}
            <div className='card-content'>
                <h5 className={`card-title ${onClick ? '' : ''}`}>
                    {title}
                </h5>
                <div className='card-project'>
                    <p className="card-project-title">
                         <span className="card-project-indicator">
                             <span className="card-project-dot"></span>
                             {activeProjectTitle}
                         </span>
                    </p>
                </div>
                <div className='card-email-container'>
                    <p className="card-email">
                        {email}
                    </p>
                </div>
                {showAddedMessage && (
                    <div className='card-added-message'>
                        <Icons.CheckCircle className="card-added-icon" />
                        <span>Added to project</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TextCard;