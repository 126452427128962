import { useEffect, useRef, useState, useCallback } from "react"
import { useAssistant, useMessages } from "../../export"
import Input from "../../../../grapes/atoms/input/Input"
import * as Icon from 'solar-icon-set'
import { useWidget } from "../../../widget/export"
import RecordRTC from "recordrtc"
import axios from "axios"
import mixpanel from "mixpanel-browser"
import { useSession } from "../../../session/export"
import SocraticToggle from "../../../socratic/components/socraticToggle"
import Tooltip from '../../../../grapes/atoms/tooltip/Tooltip';
import { useScreenShare } from "../../../screenshare/export"
import IconButton from '../../../../grapes/atoms/iconButton/IconButton';
import { useUser } from "../../../user/export"
import { useAuth } from "../../../auth/export"
import { useProject } from "../../../project/export"

type ChatInputSize = 'sm' | 'md' | 'lg';

const ChatInput = ({ variant = 'default', showTellMode = true, size = 'md' }: { variant?: 'default' | 'below', showTellMode?: boolean, size?: ChatInputSize }) => {
    const assistant = useAssistant()
    const user = useUser();
    const auth = useAuth();
    const project = useProject();
    const { messages } = useMessages()
    const session = useSession()
    const [message, setMessage] = useState<string>(messages.length === 0 ? 'I am interested in ' : '')
    const [placeholder, setPlaceholder] = useState<string>("Type here...")
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false)
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
    const recorder = useRef<RecordRTC | null>(null)
    const [isProcessingMic, setIsProcessingMic] = useState<boolean>(false)
    const [recordingDuration, setRecordingDuration] = useState<number>(0)
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const widget = useWidget()
    const recordingInterval = useRef<NodeJS.Timeout | null>(null);
    const { isScreenSharing, thunks } = useScreenShare();
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (messages.length === 0) {
            setMessage('I am interested in ');
        } else {
            setMessage('');
        }
    }, [session.data.thread?.thread_id]);

    const handleScreenShareToggle = () => {
        if (isScreenSharing) {
          thunks.stopScreenShare();
        } else {
          thunks.startScreenShare();
          if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Screen Share used', {
                $email: auth.state.data.email,
                projectTitle: project.data.title,
              });
          }
        }
      };

      const handleSubmit = async () => {
        if (assistant.data.file) {
            assistant.actions.setLoading("true")
            widget.actions.reset()
            await assistant.thunks.uploadChatFileThunk({ file: assistant.data.file });
            assistant.actions.setFile(null)
            assistant.actions.setLoading("false")
        }
        assistant.thunks.sendMessage(message)
        setMessage('')
    }

    const handleMicrophoneClick = async () => {
        if (!isRecording) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                setMediaStream(stream); // Store the stream in the state
                //@ts-ignore
                recorder.current = RecordRTC(stream, {
                    type: "audio",
                    mimeType: "audio/webm",
                });
                //@ts-ignore
                recorder.current.startRecording();
                setIsRecording(true);
                setMessage("");
                setPlaceholder("I'm listening...");
                recordingInterval.current = setInterval(() => {
                    setRecordingDuration((prevDuration) => prevDuration + 1);
                }, 1000);
            } catch (error) {
                logger.error("Error obtaining media stream:", error);
            }
        } else {
            if (recorder.current) {
                //@ts-ignore
                recorder.current.stopRecording(async function () {
                    setIsProcessingMic(true);
                    setPlaceholder("Give me a sec...");
                    //@ts-ignore
                    let blob = recorder.current.getBlob();

                    try {
                        const formData = new FormData();
                        formData.append("file", blob, "audio.webm");
                        formData.append("model", "whisper-1");
                        formData.append("response_format", "text");

                        // Send directly to OpenAI API Whispr
                        const response = await axios.post(
                            "https://api.openai.com/v1/audio/transcriptions",
                            formData,
                            {
                                headers: {
                                    "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                                    "Content-Type": "multipart/form-data"
                                }
                            }
                        );
                        const trimmedText = response.data.trim();

                        // Track the successful transcription event with Mixpanel
                        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                            mixpanel.track('Microphone used', {
                                'Transcription Length': trimmedText.length,
                                'Recording Duration': recordingDuration
                            });
                        }
                        setMessage(trimmedText);
                        setIsProcessingMic(false);
                        setRecordingDuration(0);
                        setPlaceholder("Type here...");
                    } catch (error) {
                        logger.error("Error processing audio:", error);
                        setIsProcessingMic(false);
                        setRecordingDuration(0);
                        setPlaceholder("Type here...");
                    }

                    // Use the stored stream reference to stop the tracks
                    if (mediaStream) {
                        mediaStream.getTracks().forEach((track) => {
                            track.stop();
                        });
                        setMediaStream(null); // Clear the stored stream
                    } else {
                        // logger.log("No mediaStream found.");
                    }

                    // Clear the interval when stopping the recording
                    if (recordingInterval.current) {
                        clearInterval(recordingInterval.current);
                        recordingInterval.current = null;
                    }
                });
                setRecordingDuration(0);
                setIsProcessingMic(false);
                setIsRecording(false);
            } else {
                // logger.log("Recorder not initialized or already stopped.");
            }
        }
    };

    const handleFileUpload = useCallback((file: File) => {
        widget.actions.setState('file');
        if (file) {
            setIsUploadingFile(true);
            logger.log('File selected:', file.name);
            assistant.actions.setFile(file);
            setIsUploadingFile(false);
        }
    }, [widget.actions, assistant.actions])

    const handleFileButtonClick = useCallback(() => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg,image/png';
        fileInput.onchange = (e: Event) => {
            const target = e.target as HTMLInputElement;
            const file = target.files?.[0];
            if (file) {
                handleFileUpload(file);
            }
        };
        fileInput.click();
    }, [handleFileUpload]);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDragging(true)
        widget.actions.setState('file')
    }, [])

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDragging(false)
        widget.actions.setState('none')
    }, [])

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDragging(false)
        const file = e.dataTransfer.files[0]
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            handleFileUpload(file)
        }
    }, [handleFileUpload])

    const getIconSize = (inputSize: ChatInputSize): number => {
        switch (inputSize) {
            case 'sm': return 18;
            case 'md': return 24;
            case 'lg': return 28;
        }
    };

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    return (
        <div className="relative"
        onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}
        >
            {isDragging && (
                <div className="absolute w-11/12 inset-0 bottom-0 h-[200px] -mt-[190px] rounded-md left-1/2 transform -translate-x-1/2">
                </div>
            )}
            <div className={`bg-pf-gray-T3 justify-start items-start flex ${variant === 'default' ? 'flex-row' : 'flex-col'} ${
                size === 'sm' ? 'pr-1 pl-[3px] py-[4px] rounded-xs' :
                size === 'md' ? 'pr-1 pl-[6px] py-[6px] rounded-sm' :
                size === 'lg' ? 'pr-2 pl-[6px] py-[6px] rounded-md' : 'pr-1 pl-[4px] py-[4px] rounded-sm'
            } w-full z-10 relative`}>
                {variant === 'default' && (
                    <div className={`flex h-full items-center space-x-${size === 'sm' ? '[0px]' : size === 'md' ? '[1px]' : '[4px]'} ${
                        size === 'sm' ? 'mt-1' :
                        size === 'md' ? 'mt-0.5' :
                        size === 'lg' ? 'mt-0.5' : 'mt-0.5'
                    }`}>
                        {!user.state.data.experimentTypes.includes("SCREENSHARE_USER") && (
                            <div className="hover:-translate-y-2 transition-transform flex items-center justify-start">
                                <Tooltip content="Chat with Images" position="top">
                                    <IconButton
                                        onClick={handleFileButtonClick}
                                        variant="borderless"
                                        disabled={isUploadingFile}
                                        icon={<Icon.Gallery size={getIconSize(size)} />}
                                        tooltipLabel="Chat with Images"
                                        size={size}
                                    />
                                </Tooltip>
                            </div>
                        )}
                        <div className="hover:-translate-y-2 transition-transform flex items-start justify-center">
                            <Tooltip content="Talk to me" position="top">
                                <IconButton
                                    onClick={handleMicrophoneClick}
                                    variant="borderless"
                                    className={isRecording ? `w-[${getIconSize(size) + 12}px] h-[${getIconSize(size) + 12}px] !rounded-full border-2 border-gray-500 flex items-center justify-center mr-2` : ""}
                                    disabled={isProcessingMic}
                                    icon={isRecording ? (
                                        <div className="">
                                            <p className={`text-${size === 'sm' ? 'xs' : 'sm'} font-bold text-gray-500 p-0`}>{recordingDuration}</p>
                                        </div>
                                    ) : (
                                        <Icon.Microphone2 size={getIconSize(size)} />
                                    )}
                                    tooltipLabel="Talk to me"
                                    size={size}
                                />
                            </Tooltip>
                        </div>
                        {user.state.data.experimentTypes.includes("SCREENSHARE_USER") && (
                            <div className="hover:-translate-y-2 transition-transform flex items-center justify-center">
                                <Tooltip content={isScreenSharing ? "Stop Screen Share" : "Start Screen Share"} position="top">
                                    <IconButton
                                        onClick={handleScreenShareToggle}
                                        variant="borderless"
                                        className={isScreenSharing ? "bg-red-500 text-white" : ""}
                                        icon={<Icon.ScreenShare size={getIconSize(size)} />}
                                        tooltipLabel={isScreenSharing ? "Stop Screen Share" : "Start Screen Share"}
                                        size={size}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )}
                <form onSubmit={handleSubmit} className="items-center flex flex-row space-x-4 w-full">
                    <div className="flex flex-col w-full mt-[2px]">
                        <div className="flex items-start mt-[-2px] flex-row w-full">
                            <div className={`flex-grow ${
                                size === 'sm' ? 'pl-[1px]' :
                                size === 'md' ? 'pl-[3px]' :
                                size === 'lg' ? 'pl-[4px]' : 'pl-[2px]'
                            }`}>
                                <Input
                                    ref={inputRef}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onSubmit={handleSubmit}
                                    placeholder={placeholder}
                                    disabled={isRecording || isProcessingMic}
                                    size={size === 'sm' ? 'md' : size === 'md' ? 'lg' : 'xl'}
                                />
                            </div>
                            {showTellMode && variant === 'default' && (
                                <div className={`flex-shrink-0 flex justify-start items-start h-full ml-2 ${
                                    size === 'sm' ? 'min-w-[30px] mt-0.5' :
                                    size === 'md' ? 'min-w-[40px] mt-1' :
                                    size === 'lg' ? 'min-w-[60px] mt-1' : 'min-w-[40px] mt-0.5'
                                }`}>
                                    <div className="flex py-[2px]">
                                        <SocraticToggle size={size === 'sm' ? 'xs' : size === 'md' ? 'sm' : 'md'} />
                                    </div>
                                </div>
                            )}
                        </div>
                    {variant === 'below' && (
                        <div className="flex flex-row justify-between ml-[3px] mt-[1px] mb-[-4px]">
                            <div className="flex justify-start">
                                {!user.state.data.experimentTypes.includes("SCREENSHARE_USER") && (
                                    <div className="transition-transform flex items-center justify-center mr-2">
                                        <IconButton
                                            onClick={handleFileButtonClick}
                                            variant="borderless"
                                            disabled={isUploadingFile}
                                            icon={<Icon.Gallery size={getIconSize(size)} />}
                                            text="Add Image"
                                            textPosition="right"
                                            size="xs"
                                            tooltipLabel="Chat with Images"
                                        />
                                    </div>
                                )}
                                <div className="transition-transform flex items-center justify-center mr-2">
                                    <IconButton
                                        onClick={handleMicrophoneClick}
                                        variant="borderless"
                                        className={isRecording ? `w-[${getIconSize(size) + 12}px] h-[${getIconSize(size) + 12}px] !rounded-full border-2 border-gray-500 flex items-center justify-center` : ""}
                                        disabled={isProcessingMic}
                                        icon={isRecording ? (
                                            <div className="">
                                                <p className={`text-${size === 'sm' ? 'xs' : 'sm'} font-bold text-gray-500 p-0`}>{recordingDuration}</p>
                                            </div>
                                        ) : (
                                            <Icon.Microphone2 size={getIconSize(size)} />
                                        )}
                                        text={isRecording ? "" : "Speak to me"}
                                        size='xs'
                                        textPosition="right"
                                        tooltipLabel="Talk to me"
                                    />
                                </div>
                                {user.state.data.experimentTypes.includes("SCREENSHARE_USER") && (
                                  <div className="transition-transform flex items-center justify-center">
                                    <IconButton
                                        onClick={handleScreenShareToggle}
                                        variant="borderless"
                                        className={isScreenSharing ? "bg-red-500 text-white" : ""}
                                        icon={<Icon.ScreenShare size={getIconSize(size)} />}
                                        text={isScreenSharing ? "Stop Share" : "Screen Share"}
                                        size='xs'
                                        textPosition="right"
                                        tooltipLabel={isScreenSharing ? "Stop Screen Share" : "Start Screen Share"}
                                    />
                                  </div>
                                )}
                            </div>
                            {showTellMode && (
                                <div className="flex items-center ml-2">
                                    <div className="">
                                    <SocraticToggle size='xs' />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    </div>
                    <div className={`flex items-center ${assistant.loading === "true" || message.length < 1 ? "cursor-default" : "transition-transform"}`}>
                        <Tooltip content="Send message" position="top">
                            <IconButton 
                                onClick={handleSubmit}
                                variant="primary" 
                                disabled={assistant.loading === "true" || message.length < 1}
                                icon={assistant.loading === "true" ? (
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                ) : (
                                    <Icon.ArrowRight size={getIconSize(size)} />
                                )}
                                tooltipLabel="Send message"
                                size={size === 'sm' ? 'sm' : size === 'md' ? 'sm' : 'md'}
                            />
                        </Tooltip>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChatInput