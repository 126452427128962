import React from 'react';

interface StickerProps {
    args: any;
}

const Sticker: React.FC<StickerProps> = ({ args }) => {

    const { title, status } = args ? JSON.parse(args) : { title: '', status: '' };


    return (
        <div className='flex items-center w-full py-12'>
            <div className='flex-grow h-px bg-gray-300'></div>
            <p className='font-bold text-base mx-8 text-center'>{title}</p>
            <div className='flex-grow h-px bg-gray-300'></div>
        </div>
    );
};

export default Sticker;
