import React, { useEffect } from 'react';
import {
    useVoiceAssistant,
    BarVisualizer,
    AgentState,
  } from "@livekit/components-react";
interface SimpleVoiceAssistantProps {
  onStateChange: (state: AgentState) => void;
}

export function SimpleVoiceAssistant({ onStateChange }: SimpleVoiceAssistantProps) {
  const { state, audioTrack } = useVoiceAssistant();
  
  useEffect(() => {
    onStateChange(state);
  }, [onStateChange, state]);
  
  return (
    <div className="h-[300px] max-w-[90vw] mx-auto">
      <BarVisualizer
        state={state}
        barCount={5}
        trackRef={audioTrack}
        className="agent-visualizer"
        options={{ minHeight: 24 }}
      />
    </div>
  );
}

