import React, { PropsWithChildren } from 'react';
import BoardPage from '../Board/BoardPage';
import ChatPage from '../Chat/ChatPage';
import Navbar from '../../utils/components/grapes/molecules/navbar';
import { Modal } from '../../utils/components/ducks/modal/components/Modal';
import LoadingPage from '../Loading/LoadingPage';
import { useSession } from '../../utils/components/ducks/session/export';

const MainPage: React.FC = () => {
    const session = useSession();
    // const auth = useAuth();

    // const lastMessaged = useProjectLastMessaged()

    // const [showDayN, setShowDayN] = useState(false);

    // useEffect(() => {
    //     if (lastMessaged) {
    //         const lastMessagedDate = deserializeDate(lastMessaged);
    //         const daysDifference = differenceInDays(new Date(), lastMessagedDate);
    //         setShowDayN(daysDifference !== 0);
    //     }
    // }, [lastMessaged]);

    const MainPageWrapper = (props: PropsWithChildren) => {
        return (<div className="flex flex-col h-full w-full">
            <Navbar />
            <div className="flex w-full h-full overflow-hidden">
                {props.children}
            </div>
        </div>)
    }

    // if (showDayN) {
    //     return (
    //         <MainPageWrapper>
    //             <DayNPage />
    //         </MainPageWrapper>
    //     )
    // }

    return (
        <div className='h-screen w-screen flex overflow-hidden'>
            {session.loading ?
                <LoadingPage label={"Setting Up..."} />
                :
                <>
                    <Modal />
                    <MainPageWrapper>
                        {
                            session.view === 'Board' ? (
                                <BoardPage />
                            ) : (
                                <ChatPage />
                            )
                        }
                    </MainPageWrapper>
                </>
            }
        </div>
    );
};

export default React.memo(MainPage);
