import React, { useEffect } from 'react';
import { Step } from '../../state';
import { useSteps } from '../../export';
import KanbanCard from './KanbanCard';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import Button from '../../../../grapes/atoms/button/Button';
import { useState } from 'react';
import { formatISO } from 'date-fns'

interface KanbanColumnProps {
    status: 'TODO' | 'DOING' | 'DONE';
    isDraggable?: boolean;
    isDragging: boolean; // New prop to indicate dragging state
    inputSteps?: Step[];
}

const KanbanColumn: React.FC<KanbanColumnProps> = ({ status, isDraggable = false, isDragging, inputSteps }) => {
    const { steps, thunks } = useSteps();
    const [isAddingTask, setIsAddingTask] = useState(false);

    const filteredSteps = inputSteps
        ? inputSteps.filter(step => step.status === status)
        : steps.filter(step => step.status === status);

    const getColumnColor = () => {
        switch (status) {
            case 'TODO':
                return 'bg-orange-100 text-orange-500 border-orange-300';
            case 'DOING':
                return 'bg-yellow-100 text-yellow-500';
            case 'DONE':
                return 'bg-green-100 text-green-500';
            default:
                return 'bg-gray-100 text-gray-500';
        }
    };

    const renderContent = (provided?: DroppableProvided, snapshot?: DroppableStateSnapshot) => (
        <div
            ref={provided?.innerRef}
            {...provided?.droppableProps}
            className={`flex flex-col p-2 rounded transition-colors
                ${snapshot?.isDraggingOver ? 'border-4 border-dashed border-black border-opacity-20 bg-black bg-opacity-5' : isDragging ? 'border-4 border-dashed border-black border-opacity-20' : 'border-4 border-transparent'}`}
        >
            {filteredSteps.length > 0 ? (
                filteredSteps.map((step: Step, index: number) => (
                    <KanbanCard key={step.id} step={step} index={index} isDraggable={isDraggable} inputSteps={inputSteps} />
                ))
            ) : (
                <>
                    {isDraggable && (
                        <div className="text-black text-center py-4">Drag and Drop your card here</div>
                    )}
                </>
            )}
            {isAddingTask && (
                <KanbanCard
                    step={{ id: 'new', title: '', status: "TODO", created_time: formatISO(Date.now()) }}
                    index={filteredSteps.length}
                    isDraggable={false}
                    isNew={true}
                    onSave={(title: string) => {
                        thunks.addTodo(title);
                        setIsAddingTask(false);
                    }}
                    onCancel={() => setIsAddingTask(false)}
                    inputSteps={inputSteps}
                />
            )}
            {provided?.placeholder}
        </div>
    );

    return (
        <div className='flex flex-col w-full h-full'>
            <div className={`w-full h-auto flex-col flex ${getColumnColor()} rounded p-4`}>
                <div className='flex flex-row justify-between items-center px-4 mb-4'>
                    <h1 className="text-[24px] font-semibold">{status}</h1>
                    <p>{`${filteredSteps.length} items`}</p>
                </div>
                <div className='text-black'>
                    {isDraggable ? (
                        <Droppable droppableId={status}>
                            {(provided, snapshot) => renderContent(provided, snapshot)}
                        </Droppable>
                    ) : (
                        renderContent()
                    )}
                </div>
            </div>
            {status === "TODO" && <>
                {/* <div className='absolute right-8 bottom-8 flex justify-center mt-4 z-40'>
                    <Button
                        variant='primary'
                        onClick={() => setIsAddingTask(true)}
                        className="rounded-full px-8 py-4"
                    >
                        <p className=''>{"+ Add Task"}</p>
                    </Button>
                </div> */}
                <div className='flex justify-center mt-4 w-full'>
                    {!inputSteps && (
                        <Button
                            variant='secondary'
                            onClick={() => setIsAddingTask(true)}
                            className="text-gray-600 hover:text-gray-800 w-full"
                        >
                            + Add task
                        </Button>
                    )}
                </div>
            </>}
        </div>
    );
};

export default KanbanColumn;