export interface ScreenShareState {
    isScreenSharing: boolean;
    screenshots: string[];
    lastScreenshotTime: number | null;
    loading: boolean;
}

export const initState: ScreenShareState = {
    isScreenSharing: false,
    screenshots: [],
    lastScreenshotTime: null,
    loading: false,
};