import axios from "axios";
import { setLoading, setError, setAudioData } from './slice';
import { Dispatch } from 'redux';
import mixpanel from "mixpanel-browser";
import { AccessToken } from "livekit-server-sdk";
import { EgressClient, EncodedFileType, EncodedFileOutput } from 'livekit-server-sdk';
import { FIREBASE_BASE_URL } from "../utils";

const API_KEY = process.env.REACT_APP_LIVEKIT_API_KEY;
const API_SECRET = process.env.REACT_APP_LIVEKIT_API_SECRET;
const LIVEKIT_URL = process.env.REACT_APP_LIVEKIT_URL;
const LIVEKIT_SERVER_URL = process.env.REACT_APP_LIVEKIT_SERVER_URL;

export type ConnectionDetails = {
  serverUrl: string;
  token: string;
  roomName: string;
  participantName: string;
};

export namespace VoiceAPI {
    export const generateSpeech = async (message: string, dispatch: Dispatch) => {
        dispatch(setLoading(true));
        dispatch(setError(null));

        try {
            // This is a placeholder. Replace with actual API call when available.
            const response = await axios.post(`${process.env.REACT_APP_VOICE_API_BASE_URL}/generate-speech`, { message });

            const audioData = response.data;
            dispatch(setAudioData(audioData));
            dispatch(setLoading(false));
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Voice API used', { message });
            }
            return audioData;
        } catch (error) {
            logger.error("[VOICE API] 🔴 Error generating speech:", error);
            let errorMessage = 'An unknown error occurred while generating speech';
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data 
                    ? error.response.data
                    : error.message;
            } else if (error instanceof Error) {
                errorMessage = error.message;
            }
            dispatch(setError(errorMessage));
            dispatch(setLoading(false));
            throw error;
        }
    }

    export const getConnectionDetails = async (): Promise<ConnectionDetails> => {
        try {
            if (!API_KEY || !API_SECRET || !LIVEKIT_URL || !LIVEKIT_SERVER_URL) {
                throw new Error("LiveKit configuration is incomplete");
            }

            const roomName = 'voice_assistant_room_' + Math.random().toString(36).substring(2, 15);
            const participantName = 'user_' + Math.random().toString(36).substring(2, 15);

            const at = new AccessToken(API_KEY, API_SECRET, {
                identity: participantName,
                ttl: 600, // 10 minutes in seconds
            });
            at.addGrant({ roomJoin: true, room: roomName });

            const generatedToken = await at.toJwt();

            // Start egress using EgressClient
            const egressClient = new EgressClient(LIVEKIT_SERVER_URL, API_KEY, API_SECRET);
            
            try {
                const userEmail = 'user@example.com'; // Replace with actual user email or use a variable
                const fileName = `${roomName}-{time}.mp4`;
                const key = `screenshare/${userEmail}/${fileName}`;

                const fileOutput = {
                    filepath: `s3://pathfinder-projects/${key}`,
                    fileType: EncodedFileType.MP4,
                } as EncodedFileOutput;

                await egressClient.startRoomCompositeEgress(roomName, {
                    file: fileOutput,
                }, {
                    layout: 'speaker-dark',
                    audioOnly: true,
                });
            } catch (error) {
                logger.error("[VOICE API] 🔴 Error starting egress:", error);
                // Handle the error appropriately
            }

            return {
                serverUrl: LIVEKIT_URL,
                token: generatedToken,
                roomName,
                participantName
            };
        } catch (error) {
            logger.error("[VOICE API] 🔴 Error getting connection details:", error);
            throw error;
        }
    }

    export const saveVoiceMessage = async (data: {
        userEmail: string;
        text: string;
        threadId?: number;
        title?: string;
        isAI: boolean;
      }) => {
        const response = await axios.post(
          `${FIREBASE_BASE_URL}/saveVoiceMessage`,
          data
        );
        return response.data;
    };
    
    export const getVoiceThreads = async (userEmail: string) => {
        const response = await axios.get(
          `${FIREBASE_BASE_URL}/getVoiceThreads?userEmail=${userEmail}`
        );
        return response.data;
    };
    
    export const getVoiceMessages = async (threadId: number) => {
        const response = await axios.get(
          `${FIREBASE_BASE_URL}/getVoiceMessages?threadId=${threadId}`
        );
        return response.data;
    };
}
