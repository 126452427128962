import React, { useEffect } from 'react';
import { useUser } from '../export';
import GuideThemedProjectButton from './GuideThemedProjectButton';
import { AppDispatch, RootState } from '../../../../init/reduxInit';
import { useDispatch, useSelector } from 'react-redux';
import { GuideThemedProject } from '../state';
import { UserThunks } from '../thunks';
import { useAuth } from '../../auth/export';

const TitleHeader: React.FC = () => {
    const user = useUser();
    const auth = useAuth();
    const dispatch = useDispatch<AppDispatch>();
    const guideThemedProjects = useSelector((state: RootState) => state.user.guideThemedProjects);

    useEffect(() => {
        dispatch(UserThunks.getGuideThemedProjectsThunk());
    }, [dispatch]);

    return (
        <div className="w-full justify-center flex flex-col items-center mb-8">
            {guideThemedProjects.find(project => !project.projectStarted) && (
                <GuideThemedProjectButton 
                    key={guideThemedProjects.find(project => !project.projectStarted)!.id}
                    projectTitle={guideThemedProjects.find(project => !project.projectStarted)!.projectTitle}
                    projectDescription={guideThemedProjects.find(project => !project.projectStarted)!.projectDescription}
                    projectDeliverable={guideThemedProjects.find(project => !project.projectStarted)!.projectDeliverable}
                    projectStarted={false}
                    email={auth.state.data.email}
                    guideName={guideThemedProjects.find(project => !project.projectStarted)!.guideName}
                />
            )}
            <h1 className="text-gray-800 mt-20">
                {`What are you curious about${user.state.data.name && `, ${user.state.data.name.split(" ")[0]}`}?`}
            </h1>
        </div>
    );
};

export default TitleHeader;
