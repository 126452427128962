import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../init/reduxInit';
import { Logout2, Login2 } from 'solar-icon-set';
import Tooltip from '../../../grapes/atoms/tooltip/Tooltip';
import Button from '../../../grapes/atoms/button/Button';
import { useAuth } from '../export';
import { initState } from '../state';

const AuthButton: React.FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const isAuthenticated = auth.state.data.email !== initState.data.email;
    const loading = auth.state.loading;

    return (
        <div className="flex items-center justify-center">
            {isAuthenticated ? (
                <Tooltip content="Sign out" position="bottom">
                    <Button
                        variant="secondary"
                        label="Log Out"
                        useResponsiveSize
                        onClick={() => {
                            auth.thunks.signOutThunk();
                            navigate('/login');
                        }}
                        loading={loading}
                        disabled={loading}>
                            Log Out
                    </Button>
                </Tooltip>
            ) : (
                <Tooltip content="Sign In" position="bottom">
                    <Button
                        variant="primary"
                        label="Log in"
                        onClick={() => {
                            navigate('/login');
                        }}>
                        <p>Login</p>
                    </Button>
                </Tooltip>
            )}
        </div>
    );
};

export default AuthButton;
