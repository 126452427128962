import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "../index";
import { initState, SessionState } from "./state";
import { Thread } from "@langchain/langgraph-sdk";

export const sessionSlice = createSlice({
    initialState: initState,
    name: "session",
    reducers: {

        setThread(state, action: Action<Thread>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    thread: action.payload,
                },
            };
        },

        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },

        setView(state, action: PayloadAction<SessionState['view']>) {
            return {
                ...state,
                view: action.payload,
            };
        },

        reset() {
            return {
                ...initState
            }
        }
    },
});
