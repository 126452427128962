import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../grapes/atoms/button/Button";
import IconButton from "../../../../../grapes/atoms/iconButton/IconButton";

interface DisplayStartProjectProps {
  onClick: () => void;
}

const DisplayStartProject: React.FC<DisplayStartProjectProps> = ({ onClick }) => {
  return (
    <div className="w-full h-full border-l-2 border-gray-500 flex flex-col items-center justify-center space-y-8 px-8 py-[5vh]">
        <div>
            <h1 className="text-3xl font-bold mb-4 text-center ">Launch a project to your learners</h1>
            <p className="text-gray-600 mb-8 text-center">
                Create engaging projects for your learners, allowing them to apply their knowledge in practical scenarios.
            </p>
        </div>
        <div>
            <IconButton
                variant="primary"
                icon={<FontAwesomeIcon icon={faProjectDiagram} />}
                text="Start New Project"
                textPosition="right"
                size="xl"
                onClick={() => {
                    onClick();
                }}
                tooltipLabel="Launch a project to multiple of your learners"
            />
        </div>
    </div>
  );
};

export default DisplayStartProject;