import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { setIsScreenSharing, addScreenshot, clearScreenshots } from "./slice";
import { startScreenShare, stopScreenShare } from "./thunks";
import { useMemo } from "react";
import { RootState } from "../../../init/reduxInit";

const selectIsScreenSharing = (state: RootState) => state.screenShare.isScreenSharing;
const selectScreenshots = (state: RootState) => state.screenShare.screenshots;
const selectLastScreenshotTime = (state: RootState) => state.screenShare.lastScreenshotTime;

export const useScreenShare = () => {
  const dispatch = useDispatch();

  const isScreenSharing = useSelector(selectIsScreenSharing);
  const screenshots = useSelector(selectScreenshots);
  const lastScreenshotTime = useSelector(selectLastScreenshotTime);

  const actions = useMemo(
    () => bindActionCreators(
      { setIsScreenSharing, addScreenshot, clearScreenshots },
      dispatch
    ),
    [dispatch]
  );

  const thunks = useMemo(
    () => bindActionCreators(
      { startScreenShare, stopScreenShare },
      dispatch
    ),
    [dispatch]
  );

  return {
    isScreenSharing,
    screenshots,
    lastScreenshotTime,
    actions,
    thunks,
  };
};