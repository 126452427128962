import React, { useCallback, useEffect } from 'react';
import { LiveKitRoom, RoomAudioRenderer } from "@livekit/components-react";
import '@livekit/components-styles';
import { MediaDeviceFailure } from 'livekit-client';
import { SimpleVoiceAssistant } from '../../utils/components/ducks/voice/components/simpleVoiceAssistant';
import { NoAgentNotification } from '../../utils/components/ducks/voice/components/noAgentNotification';
import PFControlBar from '../../utils/components/ducks/voice/components/pfControlBar';
import { TranscriptionDisplay } from '../../utils/components/ducks/voice/components/TranscriptionDisplay';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/init/reduxInit';
import { useVoice } from '../../utils/components/ducks/voice/export';
import VoiceNavbar from '../../utils/components/ducks/voice/components/voiceNavbar';

const VoicePage = () => {
  const { thunks, actions } = useVoice();
  const { connectionDetails, agentState } = useSelector((state: RootState) => state.voice.data);

  const onConnectButtonClicked = useCallback(() => {
    thunks.createRoomAndToken(undefined);
  }, [thunks]);

  const onMediaDeviceFailure = (failure?: MediaDeviceFailure) => {
    logger.error("Media device failure:", failure);
  };

  return (
    <main data-lk-theme="default" className="grid content-center">
      <VoiceNavbar />
      <LiveKitRoom
        token={connectionDetails?.participantToken}
        serverUrl={connectionDetails?.serverUrl}
        connect={!!connectionDetails}
        audio={true}
        video={false}
        onMediaDeviceFailure={onMediaDeviceFailure}
        onDisconnected={() => {
          logger.log("Disconnected from LiveKit");
          actions.setConnectionDetails(undefined);
        }}
        onConnected={() => {
          logger.log("Connected to LiveKit");
        }}
        onError={(error) => {
          logger.error("LiveKit error:", error);
        }}
        className="grid grid-rows-[2fr_1fr] items-center"
      >
        <RoomAudioRenderer />
        <NoAgentNotification state={agentState} />
        <SimpleVoiceAssistant onStateChange={(state) => actions.setAgentState(state)} />
        <PFControlBar onConnectButtonClicked={onConnectButtonClicked} agentState={agentState} />
        <TranscriptionDisplay />
      </LiveKitRoom>
    </main>
  );
}

export default VoicePage;