import { functionMap } from "../functions";

export type Message = {
    role: "human" | "ai" | "tool"
    content: {
        message: string,
        fileUrl?: string,
        file?: File,
    }
    tool?: {
        name: keyof typeof functionMap,
        content: string,
        version?: number
    }
    files?: {
        name: string,
        url: string,
        type: string
    }[]
    // metadata: any,
}

export type AssistantState = {
    messages: Message[];
    data: {
        file?: File | null;
        fileUrl?: string | null;
    };
    suggestions: string[] | null,
    loading: "false" | "streaming" | "true";
    // ok - default
    // running - a message is being sent
    // cancelling - cancel button pressed
    // finishing - OpenAI is doing it's thing, can't cancel now
    fileStatus: "none" | "preparing" | "uploading" | "uploaded" | "error",
    status: "ok" | "running" | "cancelling" | "finishing";
};

export const initState: AssistantState = {
    messages: [],
    data: {
    },
    suggestions: null,
    loading: "false",
    fileStatus: "none",
    status: "ok",
};
