import React from "react";
import { useSocratic } from "../export";
import SwitchToggle from "../../../grapes/atoms/switchToggle/SwitchToggle";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../auth/export";

type SocraticToggleProps = {
    size?: 'xs' | 'sm' | 'md' | 'lg';
};

const SocraticToggle: React.FC<SocraticToggleProps> = ({ size = 'sm' }) => {
    const { isSocratic, actions } = useSocratic();
    const auth = useAuth();

    return (
        <SwitchToggle
            checked={isSocratic}
            onChange={(e) => {
                actions.setIsSocratic(e.target.checked);
                // Mixpanel tracking for new chat created
                if (process.env.REACT_APP_MIXPANEL_TOKEN && isSocratic) {
                    mixpanel.track('Tell Mode on', {
                        $email: auth.state.data.email,
                    });
                }
            }}
            tooltipText={isSocratic ? "Help me think through" : "Tell me, I'm stuck"}
            size={size}
            labelPosition="left"
            label="Tell"
        />
    );
};

export default SocraticToggle;