import React, { TextareaHTMLAttributes, forwardRef, useEffect, useRef, useCallback, KeyboardEvent } from 'react';
import './input.css';

export type TextAreaSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onInput?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onSubmit?: () => void;
  size?: TextAreaSize;
  border?: boolean;
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ className, onInput, onSubmit, size = 'md', border = false, ...props }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const adjustHeight = useCallback((element: HTMLTextAreaElement) => {
        element.style.height = 'auto';
        const newHeight = Math.min(element.scrollHeight, 4 * 24); // Assuming 24px line height
        element.style.height = `${newHeight}px`;
    }, []);

    const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const target = event.target as HTMLTextAreaElement;
        adjustHeight(target);
        onInput && onInput(event);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            if (event.ctrlKey || event.metaKey) {
                // Insert a new line
                const target = event.target as HTMLTextAreaElement;
                const start = target.selectionStart;
                const end = target.selectionEnd;
                const value = target.value;
                target.value = value.substring(0, start) + '\n' + value.substring(end);
                target.selectionStart = target.selectionEnd = start + 1;
                adjustHeight(target);
                event.preventDefault();
            } else {
                // Submit the form
                event.preventDefault();
                onSubmit && onSubmit();
            }
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            adjustHeight(textareaRef.current);
        }
    }, [adjustHeight]);

    const wrapperClassName = border ? "border border-pf-gray-T1 rounded-md" : "";

    return (
        <div className={wrapperClassName}>
            <textarea
                ref={(node) => {
                    if (typeof ref === 'function') {
                        ref(node);
                    } else if (ref) {
                        ref.current = node;
                    }
                    textareaRef.current = node;
                }}
                className={`input !responsive-text${size === "md" ? "" : size === "sm" ? "-minus" : size === "lg" ? "-plus" : size === "xl" ? "-plus" : ""} ${className || ''}`}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                rows={1}
                {...props}
            />
        </div>
    );
});

export default TextArea;