import { AgentState } from "@livekit/components-react";
import { TranscriptionSegment } from 'livekit-client';

export type ConnectionDetails = {
  serverUrl: string;
  roomName: string;
  participantName: string;
  participantToken: string;
};

export type ExtendedTranscriptionSegment = TranscriptionSegment & { participantId: string };

export type VoiceState = {
    data: {
        audioData: any;
        connectionDetails: ConnectionDetails | null;
        agentState: AgentState;
        transcriptions: { [id: string]: ExtendedTranscriptionSegment };
        currentThreadId: number | null;
        loadedMessages: ExtendedTranscriptionSegment[]; 
    };
    loading: boolean;
    error: string | null;
};

export const initState: VoiceState = {
    data: {
        audioData: null,
        connectionDetails: null,
        agentState: "disconnected",
        transcriptions: {},
        currentThreadId: null,
        loadedMessages: [],
    },
    loading: false,
    error: null,
};
