import React, { useEffect, useRef } from 'react';
import { useRoomContext } from "@livekit/components-react";
import { RoomEvent, Participant, TranscriptionSegment } from 'livekit-client';
import ReactMarkdown from 'react-markdown';
import { Components } from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../init/reduxInit';
import { updateTranscriptions, voiceSlice } from '../slice';
import { VoiceAPI } from '../api';

// Add this type definition
type ExtendedTranscriptionSegment = TranscriptionSegment & { participantId: string };

// Add this new type for grouped segments
type GroupedSegment = {
  id: string;
  participantId: string;
  text: string;
  firstReceivedTime: number;
};

const customComponents: Components = {
  a: ({ node, ...props }) => (
    <a {...props} className="text-blue-600 hover:text-blue-800 underline responsive-text" target="_blank" rel="noopener noreferrer" />
  ),
  ol: ({ children, ...props }: React.HTMLAttributes<HTMLOListElement>) => (
    <ol {...props} className={`list-decimal ml-6 responsive-text ${props.className || ''}`}>
      {children}
    </ol>
  ),
  ul: ({ children, ...props }: React.HTMLAttributes<HTMLUListElement>) => (
    <ul {...props} className={`list-disc ml-6 responsive-text ${props.className || ''}`}>
      {children}
    </ul>
  ),
  li: ({ children, ...props }: React.LiHTMLAttributes<HTMLLIElement>) => (
    <li {...props} className={`mb-1 responsive-text ${props.className || ''}`}>
      {children}
    </li>
  ),
  p: ({ children, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => (
    <p {...props} className={`responsive-text whitespace-pre-wrap ${props.className || ''}`}>
      {children}
    </p>
  ),
  code: ({ node, inline, className, children, ...props }: any) => {
    return !inline ? (
      <div className="bg-gray-800 text-white p-4 rounded-md overflow-x-auto mt-4 mb-4">
        <pre className="text-sm">
          <code className={className}>
            {children}
          </code>
        </pre>
      </div>
    ) : (
      <code className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-1 py-0.5 rounded responsive-text" {...props}>
        {children}
      </code>
    );
  },
};

export const TranscriptionDisplay: React.FC = () => {
  const room = useRoomContext();
  const dispatch = useDispatch();
  const transcriptionContainerRef = useRef<HTMLDivElement>(null);
  const transcriptions = useSelector((state: RootState) => state.voice.data.transcriptions);
  const currentThreadId = useSelector((state: RootState) => state.voice.data.currentThreadId);
  const userEmail = useSelector((state: RootState) => state.auth.data.email || 'user@example.com');
  const currentSpeakerRef = useRef<string | null>(null);
  const isFirstMessageRef = useRef<boolean>(true);
  const currentMessageRef = useRef<string>('');
  const isSpeakingRef = useRef<boolean>(false);
  const speakingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const loadedMessages = useSelector((state: RootState) => state.voice.data.loadedMessages);

  useEffect(() => {
    if (!room) return;

    const handleTranscriptionReceived = async (segments: TranscriptionSegment[], participant: Participant) => {
      const extendedSegments: ExtendedTranscriptionSegment[] = segments.map(segment => ({
        ...segment,
        participantId: participant.identity
      }));
      dispatch(updateTranscriptions(extendedSegments));

      logger.log("PUBLISHING DATA")
      const strData = JSON.stringify({some: "data"})
      const encoder = new TextEncoder()
      const decoder = new TextDecoder()

      // publishData takes in a Uint8A§rray, so we need to convert it
      const data = encoder.encode(strData);
      logger.log("data: ", data);

      // Publish lossy data to the entire room
      room.localParticipant.publishData(data, {reliable: false})


      // Get the latest complete text
      const latestText = segments[segments.length - 1].text;

      // If speaker changed
      if (participant.identity !== currentSpeakerRef.current) {
        // Save previous speaker's message if exists
        if (currentSpeakerRef.current && currentMessageRef.current) {
          try {
            const response = await VoiceAPI.saveVoiceMessage({
              userEmail,
              text: currentMessageRef.current.trim(),
              threadId: currentThreadId || undefined,
              isAI: !currentSpeakerRef.current.startsWith("u")
            });

            if (isFirstMessageRef.current && !currentThreadId && response.threadId) {
              dispatch(voiceSlice.actions.setCurrentThreadId(response.threadId));
              isFirstMessageRef.current = false;
            }
          } catch (error) {
            console.error("Error saving transcription:", error);
          }
        }

        // Reset and update speaker
        currentMessageRef.current = latestText;
        currentSpeakerRef.current = participant.identity;
        isSpeakingRef.current = true;

        // Clear any existing timeout
        if (speakingTimeoutRef.current) {
          clearTimeout(speakingTimeoutRef.current);
        }
      } else {
        // Same speaker, update current message
        currentMessageRef.current = latestText;
        isSpeakingRef.current = true;

        // Reset speaking timeout
        if (speakingTimeoutRef.current) {
          clearTimeout(speakingTimeoutRef.current);
        }

        // Set new timeout to detect end of speech
        speakingTimeoutRef.current = setTimeout(async () => {
          if (isSpeakingRef.current && currentMessageRef.current) {
            try {
              const response = await VoiceAPI.saveVoiceMessage({
                userEmail,
                text: currentMessageRef.current.trim(),
                threadId: currentThreadId || undefined,
                isAI: !currentSpeakerRef.current?.startsWith("u")
              });

              if (isFirstMessageRef.current && !currentThreadId && response.threadId) {
                dispatch(voiceSlice.actions.setCurrentThreadId(response.threadId));
                isFirstMessageRef.current = false;
              }
            } catch (error) {
              console.error("Error saving transcription:", error);
            }
            isSpeakingRef.current = false;
            currentMessageRef.current = '';
          }
        }, 1000); // Wait 1 second of silence before considering speech complete
      }
    };

    room.on(RoomEvent.TranscriptionReceived, handleTranscriptionReceived as any);

    return () => {
      logger.log("disconnecting room");
      room.disconnect();
      room.off(RoomEvent.TranscriptionReceived, handleTranscriptionReceived as any);
      if (speakingTimeoutRef.current) {
        clearTimeout(speakingTimeoutRef.current);
      }
    };
  }, [room, dispatch, currentThreadId, userEmail]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (transcriptionContainerRef.current) {
        transcriptionContainerRef.current.scrollTop = transcriptionContainerRef.current.scrollHeight;
      }
    };

    // Scroll to bottom initially
    scrollToBottom();

    // Set up interval to scroll every 5 seconds
    const intervalId = setInterval(scrollToBottom, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [transcriptions]);

  const groupSegments = (segments: ExtendedTranscriptionSegment[]): GroupedSegment[] => {
    return segments.reduce((acc: GroupedSegment[], segment, index, array) => {
      if (index === 0 || segment.participantId !== array[index - 1].participantId) {
        // Start a new group
        acc.push({
          id: segment.id,
          participantId: segment.participantId,
          text: segment.text,
          firstReceivedTime: segment.firstReceivedTime,
        });
      } else {
        // Append to the previous group
        acc[acc.length - 1].text += ' ' + segment.text;
      }
      return acc;
    }, []);
  };

  // Group the segments
  // const groupedTranscriptions = groupSegments(
  //   Object.values(transcriptions).sort((a, b) => a.firstReceivedTime - b.firstReceivedTime)
  // );

  const groupedTranscriptions = groupSegments([
    ...loadedMessages,
    ...Object.values(transcriptions)
  ].sort((a, b) => a.firstReceivedTime - b.firstReceivedTime));

  return (
    <div className="flex justify-center mt-[-30px] mb-[28px]">
      <div 
        ref={transcriptionContainerRef}
        className="overflow-y-auto max-h-[calc(100vh-448px)] w-full max-w-[750px] overflow-x-clip p-4 space-y-2 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300"
      >
        {groupedTranscriptions.map((segment) => (
          <div key={segment.id} className="space-y-4">
            <div
              className={`px-3 py-2 rounded-sm text-black ${
                segment.participantId.startsWith('u')
                  ? 'bg-blue-200 ml-auto'
                  : 'bg-gray-200 mr-auto'
              } w-fit max-w-[calc(100%-24px)] relative group`}
            >
              <ReactMarkdown components={customComponents}>
                {segment.text}
              </ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
