import React from 'react';
import { useResources } from '../../export';
import { Resource } from '../../state';
import { style } from '@mui/system';
import { styles } from '../../../../../../styling/styles';

const VideoPanel: React.FC<Resource> = ({ title, url }) => {
    const resources = useResources()
    // Function to convert YouTube URL to embed URL
    const getEmbedUrl = (url: string): string => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}`;
        }
        return url; // Return original URL if not a valid YouTube URL
    };

    return (
        <div className={`flex flex-col w-full h-full ${resources.currentPage?.highlighted?.url === url ? 'bg-gray-100 rounded' : 'bg-white rounded'} justify-center px-4 py-6`}>
            <div className="mb-4">
                <h2 className="mb-2 line-clamp-3 text-ellipsis">{title}</h2>
            </div>
            <div className="aspect-video flex w-full mt-4 shadow-sm hover:shadow-md bg-black rounded-md mb-8">
                <iframe
                    className='rounded-md'
                    width="100%"
                    height="auto"
                    // controls
                    src={getEmbedUrl(url)}
                    title={title}
                    allowFullScreen
                >
                    Your browser does not support the video tag.
                </iframe>
            </div>
        </div>
    );
};

export default VideoPanel;
