import { GuideLearner } from "../../../types/GuideLearner";

export type StepState = {
    steps: Step[],
    loading: boolean;
};

export enum AnswerType {
    text = "text",
    file = "file"
}

export type Step = {
    id: string
    title: string,
    status: 'TODO' | 'DOING' | "DONE",
    parent_id?: string,
    answer?: {
        type: AnswerType,
        content: string, // either text answer or filename
    }
    created_time: string,
}

export const initState: StepState = {
    steps: [],
    loading: false,
};
