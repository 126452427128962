import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { AnswerType, Step, initState } from "./state";

export const stepsSlice = createSlice({
    initialState: initState,
    name: "steps",
    reducers: {

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            }
        },

        editStep(state, action: Action<{ id: string; step: Partial<Step> }>) {
            return {
                ...state,
                steps: state.steps.map(step =>
                    step.id === action.payload.id ? { ...step, ...action.payload.step, created_time: new Date().toISOString() } : step
                )
            };
        },

        deleteStep(state, action: Action<{ id: string }>) {
            return {
                ...state,
                steps: state.steps.filter(step => step.id !== action.payload.id)
            };
        },

        addStep(state, action: Action<{ title: string, status: "TODO" | "DOING" | "DONE" }>) {
            const newStep: Step = {
                id: `step_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
                title: action.payload.title,
                status: action.payload.status,
                created_time: new Date().toISOString()
            };

            const updatedSteps: Step[] = state.steps.map(step =>
                step.status === "DOING" ? { ...step, status: "TODO" } : step
            );

            return {
                ...state,
                steps: [...updatedSteps, newStep]
            };
        },

        addAnswer(state, action: Action<{ stepId: string; answer: { type: AnswerType, content: string } }>) {
            return {
                ...state,
                steps: state.steps.map(step =>
                    step.id === action.payload.stepId
                        ? {
                            ...step,
                            answer: action.payload.answer,
                            created_time: new Date().toISOString()
                        }
                        : step
                ),
            };
        },

        setSteps(state, action: Action<Step[]>) {
            return {
                ...state,
                steps: action.payload.map(step => ({
                    ...step,
                    id: step.id || `step_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
                    status: step.status || 'TODO',
                }))
            };
        },


        reset(state) {
            return {
                ...initState
            }
        }

    }
});