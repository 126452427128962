import { AnyAction, Dispatch } from "redux";
import { RootState, store } from "../../init/reduxInit";
import { ThunkAction } from "@reduxjs/toolkit";

// export type Thunk<T> = (props: T) => (dispatch: typeof store.dispatch, getState: () => RootState) => Promise<any>;
export type Thunk<T, R = any> = (props: T) => (dispatch: typeof store.dispatch, getState: () => RootState) => Promise<R>;


export const unknownFunction: Thunk<any> = (args: any) => async (dispatch, getState) => {
    logger.warn("Function not implemented: ", args)
}

export const serializeDate = (date: Date): string => date.toISOString();
export const deserializeDate = (dateString: string): Date => new Date(dateString);

export const FIREBASE_BASE_URL = "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net"
export const CARTESIA_API_BASE_URL = "https://api.cartesia.ai"