import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/components/ducks/auth/export";
import { useUser } from "../../utils/components/ducks/user/export";
import LoginForm from "./components/LoginForm";
import CoverPage from "../../assets/images/Cover Page.jpg";
import * as Icons from 'solar-icon-set';

export interface LoginFormData {
    email: string;
    password: string;
}

const LoginPage = () => {
    const auth = useAuth();
    const user = useUser();
    const navigate = useNavigate();

    const onSubmit = async (data: LoginFormData) => {
        logger.log("Login submission started");
        try {
            logger.log("Attempting to sign in with email:", data.email);
            await auth.thunks.signInThunk({
                username: data.email,
                password: data.password,
            });
            logger.log("Sign in successful, navigating to home page");
            if (user.state.data.role === "Teacher" || user.state.data.role === "Guide" || user.state.data.role === "Parent") {
                navigate("/guide-dashboard");
            } else {
                navigate("/");
            }
        } catch (error: any) {
            logger.log("Sign in failed");
            logger.error("Login error:", error);
            throw error;
        } finally {
            logger.log("Login process completed");
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
            <img 
                src={CoverPage} 
                alt="Cover"
                className="absolute inset-0 w-full h-full object-cover object-left-bottom"
            />
            <div className="absolute inset-0 bg-pf-black bg-opacity-10"></div>
            <div className="bg-pf-white dark:bg-pf-gray-S1 p-8 rounded-2xl w-[90%] max-w-xl relative z-10 border border-pf-gray-S2 dark:border-pf-gray transition-all duration-300 shadow-lg">
                <h2 className="text-3xl font-bold mt-2 mb-4 text-center text-pf-black dark:text-pf-white">Welcome back!</h2>
                <p className="text-pf-gray-S1 mb-6 text-center">Please sign in to your account</p>
                <div className="mb-6">
                    <LoginForm onSubmit={onSubmit} />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;