import React, { InputHTMLAttributes } from 'react';
import './switchToggle.css';
import Tooltip from '../tooltip/Tooltip';

type SwitchToggleSize = 'xs' | 'sm' | 'md' | 'lg';

interface SwitchToggleProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> {
  label?: string;
  labelPosition?: 'left' | 'right';
  tooltipText?: string;
  size?: SwitchToggleSize;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({ 
  label, 
  labelPosition = 'right', 
  className,
  tooltipText,
  size = 'md',
  disabled,
  ...props 
}) => {
  const switchToggle = (
    <div className="flex flex-row items-center">
          <label className={`switch-toggle-container switch-toggle-${size} ${labelPosition === 'left' ? 'flex-row-reverse' : 'flex-row'} ${className || ''}`}>
      {label && (
        <span className={`switch-toggle-label font-button ${labelPosition === 'left' ? (size === 'xs' ? 'ml-1' : 'ml-2') : (size === 'sm' || size === 'xs' ? 'mr-1' : 'mr-2')} ${disabled ? 'cursor-not-allowed !text-pf-gray-T1' : ''}`}>
          {label}
        </span>
      )}
      <div className="switch-toggle">
        <input
          type="checkbox"
          className="switch-toggle-input"
          disabled={disabled}
          {...props}
        />
        <span className="switch-toggle-slider"></span>
      </div>
    </label>
    </div>
  );

  return tooltipText ? (
    <Tooltip content={tooltipText}>
      {switchToggle}
    </Tooltip>
  ) : switchToggle;
};

export default SwitchToggle;