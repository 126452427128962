import { useEffect, useState } from 'react';
import { Player } from "@lottiefiles/react-lottie-player";
import loadingDark from '../../../../../assets/lottie/loading-dark.json';
import loadingLight from '../../../../../assets/lottie/loading-light.json';
import loadingAnim from '../../../../../assets/lottie/loading-anim.json';
import Tooltip from '../tooltip/Tooltip';

interface PageLoadingDivProps {
  tooltipText?: string;
  label?: string;
}

const PageLoadingDiv: React.FC<PageLoadingDivProps> = ({ tooltipText, label }) => {
  // const [isDarkMode, setIsDarkMode] = useState(false);

  // useEffect(() => {
  //   // Check if dark mode is enabled
  //   const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  //   setIsDarkMode(darkModeMediaQuery.matches);

  //   // Listen for changes in color scheme
  //   const handleColorSchemeChange = (e: MediaQueryListEvent) => {
  //     setIsDarkMode(e.matches);
  //   };
  //   darkModeMediaQuery.addEventListener('change', handleColorSchemeChange);

  //   return () => {
  //     darkModeMediaQuery.removeEventListener('change', handleColorSchemeChange);
  //   };
  // }, []);

  // const getLottieAnimation = () => {
  //   if (isDarkMode) {
  //     return loadingDark;
  //   } else {
  //     return loadingLight;
  //   }
  // };

  const loadingContent = (
    <div className="w-32 flex flex-col items-center justify-center h-32">
      <Player
        autoplay
        loop
        src={loadingDark}
        style={{ width: '100%', height: '100%' }}
      />
      {label && <p className="text-sm text-gray-500">{label}</p>}
    </div>
  );

  return (
    <div className="flex justify-center items-center h-full w-full bg-background">
      {tooltipText ? (
        <Tooltip content={tooltipText}>
          {loadingContent}
        </Tooltip>
      ) : loadingContent}
    </div>
  );
};

export default PageLoadingDiv;