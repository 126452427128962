import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
    VoiceAssistantControlBar,
    ControlBar,
    AgentState,
    DisconnectButton,
    Chat,
  } from "@livekit/components-react";
import * as Icons from "solar-icon-set";  
import { useKrispNoiseFilter } from '@livekit/components-react/krisp';
import Button from '../../../grapes/atoms/button/Button';

function PFControlBar (props: { onConnectButtonClicked: () => void; agentState: AgentState; }) {
    const krisp = useKrispNoiseFilter();
    
    useEffect(() => {
      krisp.setNoiseFilterEnabled(true);
    }, []);
    
    return (
      <div className="relative justify-center flex items-center h-[50px]">
          {props.agentState === "disconnected" && (
            <motion.div
              initial={{ opacity: 0, top: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, top: "-10px" }}
              transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
              className="absolute left-1/2 -translate-x-1/2"
            >
              <Button
                variant="primary"
                onClick={() => props.onConnectButtonClicked()}
                className="uppercase"
              >
                Start a conversation
              </Button>
            </motion.div>
          )}
          {props.agentState !== "disconnected" && props.agentState !== "connecting" && (
            <div className="flex justify-between">
              <VoiceAssistantControlBar controls={{ leave: false }}/>
              <ControlBar variation="verbose" controls={{ camera: false, microphone: false, screenShare: true }}/>
            </div> 
          )}
      </div>
    );
  }

export default PFControlBar;