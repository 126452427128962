import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index";
import { initState } from "./state";

export const screenShareSlice = createSlice({
    initialState: initState,
    name: "screenShare",
    reducers: {
        setIsScreenSharing(state, action: Action<boolean>) {
            return {
                ...state,
                isScreenSharing: action.payload,
            };
        },

        addScreenshot(state, action: Action<string>) {
            return {
                ...state,
                screenshots: [...state.screenshots, action.payload],
            };
        },

        setLastScreenshotTime(state, action: Action<number>) {
            return {
                ...state,
                lastScreenshotTime: action.payload,
            };
        },

        clearScreenshots(state) {
            return {
                ...state,
                screenshots: [],
            };
        },

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
    },

        reset(state) {
            return {
                ...initState
            };
        }
    },
});

export const { 
    setIsScreenSharing, 
    addScreenshot, 
    setLastScreenshotTime, 
    clearScreenshots, 
    setLoading, 
    reset 
} = screenShareSlice.actions;

export default screenShareSlice.reducer;