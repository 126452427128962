import React from 'react';
import { useResources } from '../../export';
import { Resource } from '../../state';
import { style } from '@mui/system';
import { styles } from '../../../../../../styling/styles';

const ArticlePanel: React.FC<Resource> = ({ title, url, content }) => {
    const resources = useResources()
    return (
        <div className={`flex flex-col w-full h-full ${resources.currentPage?.highlighted?.url === url ? 'bg-gray-100 rounded' : 'bg-white'} justify-center px-4 py-4`}>
            <div className="my-4">
                <h2 className="mb-2 line-clamp-3 text-ellipsis">{title}</h2>
            </div>
            <div className="flex w-full mt-4 mb-8 shadow-sm hover:shadow-md bg-white rounded">
                <button
                    onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
                    className="w-full flex flex-col items-start text-left rounded overflow-hidden border-gray-400 border-2 border-dashed"
                >
                    <div className="w-full p-4 bg-gray-100 flex items-center justify-center overflow-hidden">
                        <img
                            src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${encodeURIComponent(url)}&size=128`}
                            alt={title}
                            className="w-24 h-24 object-contain"
                            onError={(e) => {
                                e.currentTarget.src = 'https://via.placeholder.com/128?text=No+Icon';
                            }}
                        />
                    </div>
                    <div className="p-4">
                        <h3 className="text-lg font-semibold mb-2 line-clamp-2">{title}</h3>
                        <p className="text-sm text-gray-600 line-clamp-3">
                            Click to open the article in a new tab.
                        </p>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default ArticlePanel;
