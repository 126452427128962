import Hotjar from '@hotjar/browser';
import { PropsWithChildren } from 'react';

export const HotjarInit = (props: PropsWithChildren) => {
    if (process.env.REACT_APP_HOTJAR_SITE_ID) {
        const siteId = parseInt(process.env.REACT_APP_HOTJAR_SITE_ID);
        const hotjarVersion = 6;
        logger.log('Hotjar working:', siteId);
        Hotjar.init(siteId, hotjarVersion);
    }

    return (
        <>
            {props.children}
        </>
    )
};