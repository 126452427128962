import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { AuthState, initState } from "./state";

export const socraticSlice = createSlice({
    initialState: initState,
    name: "socratic",
    reducers: {

        setIsSocratic(state, action: Action<boolean>) {
            state.isSocratic = action.payload;
        },

        reset(state) {
            return {
                ...initState,
                initialized: true,
            }
        }
    }
});