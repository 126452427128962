import { client } from "../../../init/pathFinderInit";
import { panelSlice } from "../panel/slice";
import { toastSlice } from "../toast/slice";
import { Thunk } from "../utils";
import { sessionSlice } from "./slice";
import { API as AssistantAPI } from "../assistant/api";
import { assistantSlice } from "../assistant/slice";
import { widgetSlice } from "../widget/slice";
import { optionsSlice } from "../options/slice";
import { projectImagesSlice } from "../project/images/slice";
import { projectInspirationsSlice } from "../project/inspirations/slice";
import { projectResourcesSlice } from "../project/resources/slice";
import { projectSlice } from "../project/slice";
import { projectUploadSlice } from "../project/progress-upload/slice";
import { stepsSlice } from "../steps/slice";
import { API } from "../project/api";
import mixpanel from "mixpanel-browser";
import { ProjectThunks } from "../project/thunks";
import { UserThunks } from "../user/thunks";
import { AssistantThunks } from "../assistant/thunks";

export namespace SessionThunks {

    export const createThread: Thunk<string | undefined> = (threadId?: string) => async (dispatch, getState) => {
        try {
            logger.log("[SESSION THUNK] 🔵 Starting create thread", threadId);
            dispatch(sessionSlice.actions.setLoading(true))
            if (threadId === undefined) {
                const response = await AssistantAPI.createThread();
                dispatch(sessionSlice.actions.setThread(response))
            } else {
                dispatch(sessionSlice.actions.setThread(
                    { thread_id: threadId!, created_at: "", metadata: {}, updated_at: "" }
                ))
            }
            dispatch(assistantSlice.actions.setSuggestions([]))
            dispatch(toastSlice.actions.setToast({
                type: "info",
                message: "New project created.",
                error: null,
                fatal: true
            }));

            if (getState().auth.data.email) {
                await API.saveProject({
                    thread: getState().session.data.thread!,
                    userEmail: getState().auth.data.email,
                });
            }


        } catch (error: any) {
            logger.log("[SESSION THUNK] 🔴 Error creating thread", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to create a new project.",
                error: error.message || "Unknown error occurred",
                fatal: true
            }));
        } finally {
            logger.log("[ASSISTANT THUNK] 🟢 Create thread complete");
            dispatch(sessionSlice.actions.setLoading(false))
        }
    };

    export const newSession: Thunk<string | undefined> = (threadId?: string) => async (dispatch, getState) => {
        try {
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Session Messages', { count: getState().session.data.messagesCount });
            }
            dispatch(sessionSlice.actions.reset())
            dispatch(assistantSlice.actions.reset())
            dispatch(optionsSlice.actions.reset())
            dispatch(panelSlice.actions.reset())
            dispatch(widgetSlice.actions.reset())
            dispatch(toastSlice.actions.reset())
            dispatch(projectImagesSlice.actions.reset())
            dispatch(projectInspirationsSlice.actions.reset())
            dispatch(projectResourcesSlice.actions.reset())
            dispatch(projectSlice.actions.reset())
            dispatch(projectUploadSlice.actions.reset())
            dispatch(stepsSlice.actions.reset())

            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Session');
                mixpanel.time_event('Session');
            }

            if (threadId) dispatch(sessionSlice.actions.setThread({ thread_id: threadId, created_at: "", metadata: {}, updated_at: "" }))

            // dispatch(createThread(threadId))

        } catch (error: any) {
            logger.log("[SESSION THUNK] 🔴 Error creating thread", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to create a new project.",
                error: error.message || "Unknown error occurred",
                fatal: true
            }));
        } finally {
            logger.log("[ASSISTANT THUNK] 🟢 Create thread complete");
        }
    };

    export const doWhenNoThread: Thunk<void> = () => async (dispatch, getState) => {
        dispatch(SessionThunks.newSession(undefined))
        dispatch(SessionThunks.createThread(undefined))
    }

    export const doWhenThread: Thunk<void> = () => async (dispatch, getState) => {
        dispatch(AssistantThunks.getMessagesThunk())
    }

    export const finishUp: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[SESSION THUNK] 🎬 Finishing up");
        dispatch(ProjectThunks.fetchProjectThunk())
        dispatch(UserThunks.getProjectsByEmailThunk())
        dispatch(UserThunks.getGuideThemedProjectsThunk())
        logger.log("[SESSION THUNK] 🎬 Finished up");
    }


}
