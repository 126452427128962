import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { socraticSlice } from "./slice";
import { useMemo } from "react";
import { initState } from "./state";

// Create selectors
const selectSocraticState = (state: RootState) => state.socratic.isSocratic;

export const useSocratic = () => {
    const dispatch = useDispatch();

    // Use specific selector instead of selecting the entire state
    const isSocratic = useSelector(selectSocraticState);

    // Memoize actions
    const actions = useMemo(
        () => bindActionCreators(socraticSlice.actions, dispatch),
        [dispatch]
    );

    return { isSocratic, actions };
};