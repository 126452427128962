import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../grapes/atoms/button/Button';
import { useAppDispatch } from '../../../../../../init/reduxInit';
import { UserThunks } from '../../../thunks';
import { CheckCircle } from 'solar-icon-set';
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import axios from 'axios';
import { API } from "../../../api";
// Common class names
const inputClassName = "p-2 flex-1 border bg-gray-100 rounded-md border-gray-300 focus:outline-none focus:border-blue-500";
const removeButtonClassName = "text-red-500 hover:text-red-700";
const addButtonClassName = "border-2 my-8 border-dashed border-gray-300 rounded-md px-4 py-2 text-gray-500 hover:text-gray-700 hover:border-gray-500 cursor-pointer";

// Define status types
type LearnerStatus = 'idle' | 'pending' | 'success' | 'error';

interface Learner {
    name: string;
    email: string;
    password: string;
}

interface AddLearnersFormProps {
    userEmail: string;
    onClose: () => void;
}

const AddLearnersForm: React.FC<AddLearnersFormProps> = ({ userEmail, onClose }) => {
    const [learners, setLearners] = useState<Learner[]>([{ name: "", email: "", password: "" }]);
    const [passwordShown, setPasswordShown] = useState<boolean[]>([]);
    const [learnerStatuses, setLearnerStatuses] = useState<LearnerStatus[]>([]);
    const [createAccountsLoading, setCreateAccountsLoading] = useState(false);
    const [learnerErrors, setLearnerErrors] = useState<(string | null)[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Update this effect to only add new status entries
        setLearnerStatuses(prevStatuses => {
            const newStatuses = [...prevStatuses];
            while (newStatuses.length < learners.length) {
                newStatuses.push('idle');
            }
            return newStatuses;
        });
        setLearnerErrors(prevErrors => {
            const newErrors = [...prevErrors];
            while (newErrors.length < learners.length) {
                newErrors.push(null);
            }
            return newErrors;
        });
        setPasswordShown(prevShown => {
            const newShown = [...prevShown];
            while (newShown.length < learners.length) {
                newShown.push(false);
            }
            return newShown;
        });
    }, [learners.length]);

    const handleInputChange = (index: number, field: keyof Learner, value: string) => {
        const updatedLearners = [...learners];
        updatedLearners[index][field] = value;
        setLearners(updatedLearners);
    };

    const addLearner = () => {
        setLearners(prevLearners => [...prevLearners, { name: '', email: '', password: '' }]);
        // No need to update statuses here, the useEffect will handle it
    };

    const removeLearner = (index: number) => {
        const updatedLearners = learners.filter((_, i) => i !== index);
        setLearners(updatedLearners);
        setLearnerStatuses(prev => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setCreateAccountsLoading(true);
        setLearnerErrors(learners.map(() => null));

        const updatedStatuses: LearnerStatus[] = [...learnerStatuses];
        const updatedErrors: (string | null)[] = [...learnerErrors];

        const creationPromises = learners.map((learner, index) => {
            if (learnerStatuses[index] !== 'success') {
                updatedStatuses[index] = 'pending';
                setLearnerStatuses([...updatedStatuses]);
        
                return dispatch(UserThunks.createSingleLearnerAccount({
                    guideEmail: userEmail,
                    learner: learner
                })).unwrap()
                    .then((result) => {
                        updatedStatuses[index] = 'success';
                        setLearnerStatuses([...updatedStatuses]);
                        return { ...result, success: true }; // Ensure we're returning a result object
                    })
                    .catch((error: any) => {
                        updatedStatuses[index] = 'error';
                        updatedErrors[index] = error.error;
                        setLearnerStatuses([...updatedStatuses]);
                        setLearnerErrors([...updatedErrors]);
                        return { email: learner.email, success: false, error: error.error }; // Return a result object even for failures
                    });
            }
            return Promise.resolve({ email: learner.email, success: false }); // Return a result object for skipped learners
        });
        
        const results = await Promise.all(creationPromises);
        
        const successfulAccounts = results.filter((result) => result && result.success === true);
        logger.log("Successful accounts: ", successfulAccounts)
        if (successfulAccounts.length > 0) {
            try {
                const guideInfoResponse = await API.getUserInfo({ email: userEmail });
                const guideName = guideInfoResponse.data.name;
                const learnerAccountDetails = successfulAccounts.map((account: any, index: number) => {
                    const learner = learners.find(l => l.email === account.email);
                    return `${index + 1}. Name - ${learner?.name}
                            Email - ${learner?.email}
                            Password - ${learner?.password}`;
                }).join("\n\n");
    
                const emailData = {
                    email: userEmail,
                    transactionalId: "cm168a1tq00ehhzf2v8awms8h", 
                    addToAudience: true,
                    dataVariables: {
                        guideName: guideName,
                        numberOfAccounts: successfulAccounts.length,
                        learnerAccountDetails: learnerAccountDetails
                    }
                };
    
                await axios.post("https://app.loops.so/api/v1/transactional", emailData, {
                    headers: {
                        "Authorization": `Bearer ${process.env.REACT_APP_LOOPS_API_KEY}`,
                        "Content-Type": "application/json"
                    }
                });
                logger.log("[ADD LEARNERS FORM] ✅ Sent email to guide about created accounts");
            } catch (error) {
                logger.error("Error sending transactional email:", error);
            }
        }

        const allSuccess = updatedStatuses.every(status => status === 'success');
        if (allSuccess) {
            onClose();
        }

        setCreateAccountsLoading(false);
    };

    const togglePasswordVisibility = (index: number) => {
        setPasswordShown(prev => {
            const newState = [...prev];
            newState[index] = !newState[index];
            return newState;
        });
    };

    return (
        <div className="space-y-8">
            <div>
                <h2 className="text-3xl font-bold mb-2">Create new learner accounts</h2>
                <p className='text-sm text-gray-600 mb-6'>Passwords need to be {'>'}5 characters. If your learners don't have an email, create a fake one (e.g. amaan@sid.com) and reach out if they ever forget their password!</p>
                {learners.map((learner, index) => (
                    <div key={index} className="mb-4">
                        <div className="flex space-x-2 items-center">
                            {learnerStatuses[index] === 'success' ? (
                                <div className='flex w-full gap-2 bg-green-500 bg-opacity-10 border border-green-500 rounded-md p-2'>
                                    <div className={`${inputClassName} bg-white`}>{learner.name}</div>
                                    <div className={`${inputClassName} bg-white truncate`}>{learner.email}</div>
                                    <div className={`${inputClassName} bg-white`}>{learner.password}</div>
                                </div>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        value={learner.name}
                                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                        placeholder="Name"
                                        className={inputClassName}
                                        required
                                    />
                                    <input
                                        type="email"
                                        value={learner.email}
                                        onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                        placeholder="Email"
                                        className={inputClassName}
                                        required
                                    />
                                    <div className="relative flex-1">
                                        <input
                                            type={passwordShown[index] ? "text" : "password"}
                                            value={learner.password}
                                            onChange={(e) => handleInputChange(index, 'password', e.target.value)}
                                            placeholder="Password"
                                            className={`sensitive ${inputClassName} pr-10`}
                                            required
                                        />
                                        <span
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => togglePasswordVisibility(index)}
                                        >
                                            {passwordShown[index] ? (
                                                <VisibilityOffSharpIcon className="h-5 w-5 text-gray-500" />
                                            ) : (
                                                <VisibilitySharpIcon className="h-5 w-5 text-gray-500" />
                                            )}
                                        </span>
                                    </div>
                                </>
                            )}
                            <button
                                type="button"
                                onClick={() => removeLearner(index)}
                                className={removeButtonClassName}
                                disabled={learnerStatuses[index] === 'success'}
                            >
                                {learnerStatuses[index] !== 'success' && (
                                    <FontAwesomeIcon icon={faTimes} />
                                )}
                            </button>
                        </div>
                        {/* Display status and error */}
                        <div className="mt-1 flex items-center space-x-2">
                            {learnerStatuses[index] === 'pending' && (
                                <div className="flex items-center text-blue-500">
                                    <svg className="animate-spin h-3 w-3 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Creating...</span>
                                </div>
                            )}
                            {learnerStatuses[index] === 'success' && (
                                <span className="text-green-500 flex items-center">
                                    <CheckCircle size={16} className="mr-1" />
                                    Created Successfully
                                </span>
                            )}
                            {learnerStatuses[index] === 'error' && (
                                <div className="ml-2 text-red-500">
                                    ✕ Failed to Create - {learnerErrors[index]}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                <div
                    className={addButtonClassName}
                    onClick={addLearner}
                >
                    Add a new learner
                </div>
                <div className="flex justify-end mt-4">
                    <Button
                        variant='primary'
                        onClick={handleSubmit}
                        loading={createAccountsLoading}
                        label="Create accounts"
                    >
                        <p>Create accounts</p>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddLearnersForm;