import React, { useEffect, useState } from 'react';
import * as Icons from 'solar-icon-set'
import { styles } from '../../../../styling/styles';
import ImagePanel from '../../project/images/components/ImagePanel';
import ResourcePanel from '../../project/resources/components/Panel';
import InspirationPanel from '../../project/inspirations/components/Panel';
import SummaryPanel from '../../project/summary/components/SummaryPanel';
import StepsPanel from '../../steps/components/Panel';
import ProgressUploadWidget from '../../project/progress-upload/components/Widget';
import { useModal } from '../export';
import ModalHeader from './ModalHeader';
import LoginForm from '../../../../../pages/Login/components/LoginForm';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../user/export';
import { useAuth } from '../../auth/export';
import { LoginFormData } from '../../../../../pages/Login/LoginPage';

export const Modal = () => {
    const { state, actions } = useModal();
    const [isVisible, setIsVisible] = useState(false);
    const auth = useAuth();
    const user = useUser();
    const navigate = useNavigate();

    const onSubmit = async (data: LoginFormData) => {
        logger.log("Login submission started");
        try {
            logger.log("Attempting to sign in with email:", data.email);
            await auth.thunks.signInThunk({
                username: data.email,
                password: data.password,
            });
            logger.log("Sign in successful, navigating to home page");
            if (user.state.data.role === "Teacher" || user.state.data.role === "Guide" || user.state.data.role === "Parent") {
                navigate("/guide-dashboard");
            } else {
                navigate("/");
            }
        } catch (error: any) {
            logger.log("Sign in failed");
            logger.error("Login error:", error);
            throw error;
        } finally {
            logger.log("Login process completed");
            actions.setState('none')
        }
    };

    return (
        <div className={`absolute top-0 left-0 inset-0 z-50 flex items-center justify-center ${state === "none" ? 'invisible' : 'visible'}`}>
            <div className="fixed inset-0 bg-black opacity-50" onClick={() => actions.setState('none')}></div>
            <div className="bg-white rounded-md shadow-xmdl z-10 p-6 max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mt-[-1rem] mr-[-1rem] mb-2">
                    {/* <h2 className="text-2xl font-bold">Modal Title</h2> */}
                    <ModalHeader />
                </div>
                <div className="mt-[-1rem] mb-6">
                    {state === 'login' && <LoginForm onSubmit={onSubmit} title="Login to continue where you left off:" />}
                </div>
            </div>
        </div>
    );
};