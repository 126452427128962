import React, { useState, useEffect } from 'react';
import Button from '../../../../../grapes/atoms/button/Button';
import { useParams } from 'react-router-dom';
import { API as ProjectAPI } from "../../../../project/api"
import { Skeleton } from '@mui/material';
import * as Icons from "solar-icon-set"
import { Project } from '../../../../project/state';
import { toastSlice } from '../../../../toast/slice';
import pfLogo from '../../../../../../../assets/images/pf-icon.svg';

const ProjectSidebar: React.FC<{
    currentProject: Project | null;
    setCurrentProject: React.Dispatch<React.SetStateAction<Project | null>>;
}> = ({ currentProject, setCurrentProject }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState<Project[]>([]);
    const { email } = useParams<{ email: string }>();

    useEffect(() => {
        logger.log("[PROJECT SIDEBAR] 🎬 Fetching projects");
        if (email) {
          setIsLoading(true);
          ProjectAPI.getProjectsByEmail({ email: email })
            .then((response) => {
              if (response.status === 200) {
                logger.log("[PROJECT SIDEBAR] ✅ Projects fetched successfully");
                return response.data.threadIdsAndTitles;
              } else {
                throw new Error("Failed to fetch projects");
              }
            })
            .then((projectThreadIdsAndTitles: { threadId: string; projectTitle: string }[]) => {
              if (projectThreadIdsAndTitles && projectThreadIdsAndTitles.length > 0) {
                logger.log("[PROJECT SIDEBAR] 🎬 Fetching project details", projectThreadIdsAndTitles);
                return Promise.all(projectThreadIdsAndTitles.map((project: { threadId: string; projectTitle: string }) =>
                  ProjectAPI.getProjectByThread({ thread: { thread_id: project.threadId, created_at: "", updated_at: "", metadata: {} } })
                ));
              }
              return [];
            })
            .then(responses => {
              const projectDetails = responses
                .filter(response => response.status === 200)
                .map(response => {
                  const data = response.data;
                  return {
                    threadId: data.threadId,
                    title: data.projectTitle,
                    description: data.projectDescription,
                    image: { url: data.imageUrl },
                    inputSteps: data.steps.length > 0 ? JSON.parse(data.steps) : {},
                    summary: data.projectSummary || '',
                    guidePrompt: data.teacherPrompt,
                    lastMessaged: data.lastMessaged
                  } as Project;
                });
              logger.log("[PROJECT SIDEBAR] ✅ Setting projects");
              setProjects(projectDetails);
            })
            .catch(error => {
              logger.log("[PROJECT SIDEBAR] 🔴 Error fetching project details:", error);
              toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch project details",
                error: error.message,
                fatal: false
              });
            })
            .finally(() => {
              setIsLoading(false);
              logger.log("[PROJECT SIDEBAR] 🔚 Project fetching complete");
            });
        }
    }, [email]);

    return (
        <div className={`flex-start h-screen overflow-y-auto overflow-x-hidden transition-all duration-300 ${isOpen ? 'w-[20rem] min-w-[16rem]' : 'w-[128px] min-w-[116px]'} bg-gray-100 flex flex-start flex-col z-10`}>
            <div className='w-full flex justify-end'>
                <button
                    className="m-2 hover:bg-pf-gray-T2 px-2 mr-2 ml-12 pt-2 pb-1 rounded-md"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? <Icons.ArrowLeftDown /> : <Icons.ArrowRightUp />}
                </button>
            </div>
            <div className="flex flex-col w-full px-4">
                <div className={`flex w-full flex-col`}>
                    <h2 className={`${isOpen ? 'text-2xl mb-4' : 'mb-2 text-lg'} whitespace-nowrap transition-all duration-200`}>Projects</h2>
                    <div className="w-full">
                        {isLoading ? (
                            <div className="w-full flex flex-col space-y-3">
                                <Skeleton variant="rectangular" width={isOpen ? "100%" : 80} height={80} />
                                <Skeleton variant="rectangular" width={isOpen ? "100%" : 80} height={80} />
                                <Skeleton variant="rectangular" width={isOpen ? "100%" : 80} height={80} />
                                <Skeleton variant="rectangular" width={isOpen ? "100%" : 80} height={80} />
                            </div>
                        ) : (
                            <div className="flex flex-col space-y-3 w-full">
                                {projects.map((project) => (
                                    project.title && (
                                        <Button
                                            key={project.description}
                                            variant="secondary"
                                            className={`flex !justify-start !pl-2 ${isOpen ? '!pr-4 !w-full' : '!w-[84px] !px-0'} overflow-hidden ${project.title === currentProject?.title ? '!bg-pf-emerald-T2' : ''}`}
                                            onClick={() => setCurrentProject(project)}
                                        >
                                            <div className="flex items-center justify-start w-full">
                                                {project.image ? (
                                                    <img
                                                        src={project.image.url === "pfLogo" ? pfLogo : project.image.url}
                                                        alt={project.title}
                                                        className={`w-16 h-16 rounded-md object-cover flex-shrink-0 ${isOpen ? 'mr-3' : ''}`}
                                                    />
                                                ) : (
                                                    <div className="w-16 h-16 bg-pf-gray-T2 rounded-md flex items-center justify-center flex-shrink-0">
                                                        {project.title.charAt(0)}
                                                    </div>
                                                )}
                                                {isOpen && (
                                                    <p className={`truncate ${project.title === currentProject?.title ? 'font-bold' : ''}`}>{project.title}</p>
                                                )}
                                            </div>
                                        </Button>
                                    )
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectSidebar;
