import React from 'react';
import Button from '../../../../../grapes/atoms/button/Button';
import { useNavigate } from 'react-router-dom';
import * as Icons from 'solar-icon-set';
import AdditionalInstructionsInput from "./AdditionalInstructionsInput"
import IconButton from '../../../../../grapes/atoms/iconButton/IconButton';

interface GuideHeroCardProps {
    heroText: string;
    email?: string;
    buttonInfo: {
        onClick: () => void;
        text: string;
    };
    isLearnerView?: boolean;
    learnerInstructions?: {
        instructionTitle: string;
        instructionDescription: string;
        placeholderText: string;
        currentInstructions: string;
        onSubmit: (prompt: string) => void;
    };
}

const GuideHeroCard: React.FC<GuideHeroCardProps> = ({ 
    heroText, 
    email,
    buttonInfo, 
    isLearnerView, 
    learnerInstructions 
}) => {
    const navigate = useNavigate()

    return (
        <div className="bg-gray-200 dark:bg-pf-green w-full mt-[0px] min-h-[200px] py-4 px-8 flex justify-between items-center">
            <div className='flex flex-start space-x-4'>
                {isLearnerView ? (
                    <IconButton
                        variant="primary"
                        onClick={() => navigate(-1)}
                        icon={<Icons.ArrowLeft />}
                        size="xl"
                        className="px-4"
                    />
                ) : (
                    <></>
                )}
                <div className="flex flex-col">
                    <h2 className="text-black dark:text-pf-navy">
                        {heroText}
                    </h2>
                    <p className="text-md text-black dark:text-pf-navy mt-1">
                        {email}
                    </p>
                </div>
            </div>
            {isLearnerView && learnerInstructions ? (
                <div className="w-1/2">
                    <AdditionalInstructionsInput
                        instructionTitle={learnerInstructions.instructionTitle}
                        instructionDescription={learnerInstructions.instructionDescription}
                        placeholderText={learnerInstructions.placeholderText}
                        currentInstructions={learnerInstructions.currentInstructions}
                        onSubmit={learnerInstructions.onSubmit}
                        isHorizontal={true}
                    />
                </div>
            ) : (
                <Button
                    variant="primary"
                    label="Go to learner view"
                    onClick={buttonInfo.onClick}
                >
                    <p className="text-lg font-bold">{buttonInfo.text}</p>
                </Button>
            )}
        </div>
    );
};

export default GuideHeroCard;