import React, { useEffect, useRef, useState } from "react"
import ReactMarkdown from 'react-markdown' 
import { functionMap } from "../../../functions"
import { useAssistant, useMessages } from "../../export"
import { Message } from "../../state"
import { Player } from "@lottiefiles/react-lottie-player";
import animationLottie from '../../../../../../assets/lottie/loading-anim.json'
import * as Icon from "solar-icon-set"
import Button from "../../../../grapes/atoms/button/Button"
import { useCartesia } from "../../../cartesia/export" 
import Tooltip from "../../../../grapes/atoms/tooltip/Tooltip"
import * as Icons from 'solar-icon-set';
import { AssistantThunks } from "../../thunks";
import { Components } from 'react-markdown'
import { useSelector, useDispatch } from "react-redux"
import { AppDispatch } from "../../../../../init/reduxInit"
import IconButton from "../../../../grapes/atoms/iconButton/IconButton"
import '../../../../../styles/responsive-text.css'
import mixpanel from "mixpanel-browser"
import { useAuth } from "../../../auth/export"

const CodeBlock = ({ className, children }: { className?: string, children: React.ReactNode }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(String(children).replace(/\n$/, ''));
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="relative mt-4 mb-4">
            <div className="absolute right-2 top-2">
                <button
                    onClick={copyToClipboard}
                    className="p-2 pb-1 rounded-md bg-gray-700 hover:bg-gray-600 text-white"
                    title="Copy code"
                >
                    {copied ? (
                        <Icons.CheckCircle size={20} />
                    ) : (
                        <Icons.Copy size={20} />
                    )}
                </button>
            </div>
            <div className="bg-gray-800 text-white p-4 rounded-md overflow-x-auto">
                <pre className="text-sm">
                    <code className={className}>
                        {children}
                    </code>
                </pre>
            </div>
            {copied && (
                <div className="absolute text-xs right-2 top-12 bg-green-500 text-white px-2 py-1 rounded-md">
                    Copied!
                </div>
            )}
        </div>
    );
};

const ChatHistory = () => {
    const { messages } = useMessages()
    const { loading } = useAssistant()
    const auth = useAuth()  
    const cartesia = useCartesia()
    const chatEndRef = useRef<HTMLDivElement | null>(null)
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [playingMessageIndex, setPlayingMessageIndex] = useState<number | null>(null);

    useEffect(() => {
        // Scroll to the bottom of the chat container
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages, loading]) // Trigger on messages change

    const handlePlay = async (message: string, index: number) => {
        // Stop any currently playing audio
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    
        try {
            // Generate and play new audio
            const audio = await cartesia.thunks.generateSpeechThunk(message);
            if (audio instanceof HTMLAudioElement) {
                audioRef.current = audio;
                setPlayingMessageIndex(index);
                audio.onended = () => setPlayingMessageIndex(null);
            } else {
                logger.error("Expected HTMLAudioElement, but received:", audio);
            }
        } catch (error) {
            logger.error("Error playing audio:", error);
        }
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        setPlayingMessageIndex(null);
    };

    // Add this custom component for rendering list items
    const customComponents: Components = {
        a: ({ node, ...props }) => (
            <a {...props} className="text-blue-600 hover:text-blue-800 underline responsive-text" target="_blank" rel="noopener noreferrer" />
        ),
        ol: ({ children, ...props }: React.HTMLAttributes<HTMLOListElement>) => (
            <ol {...props} className={`list-decimal ml-6 responsive-text ${props.className || ''}`}>
                {children}
            </ol>
        ),
        ul: ({ children, ...props }: React.HTMLAttributes<HTMLUListElement>) => (
            <ul {...props} className={`list-disc ml-6 responsive-text ${props.className || ''}`}>
                {children}
            </ul>
        ),
        li: ({ children, ...props }: React.LiHTMLAttributes<HTMLLIElement>) => (
            <li {...props} className={`mb-1 responsive-text ${props.className || ''}`}>
                {children}
            </li>
        ),
        p: ({ children, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => (
            <p {...props} className={`responsive-text whitespace-pre-wrap mb-4 ${props.className || ''}`}>
                {children}
            </p>
        ),
        code: ({ node, inline, className, children, ...props }: any) => {
            return !inline ? (
                <CodeBlock className={className}>
                    {children}
                </CodeBlock>
            ) : (
                <code className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-1 py-0.5 rounded responsive-text" {...props}>
                    {children}
                </code>
            );
        },
    }

    return (
        <div className="overflow-y-auto overflow-x-clip p-4 space-y-4 -mb-8 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300">
            {messages.map((message: Message, index: number) => {
                if (index === messages.length - 1 && (loading === "true" || (message.content.message.length < 1 && !message.tool))) {
                    return (
                        <div key={index} className={`p-3 rounded-sm min-h-12 w-fit min-w-[60%] justify-start flex`}>
                            <Player
                                src={animationLottie}
                                background="transparent"
                                style={{ width: '24px', height: '24px' }}
                                loop
                                autoplay
                            />
                        </div>
                    )
                } else {
                    return (
                        <div key={index} className="space-y-4">
                            {
                                message.role === "human" ? (
                                    (message.content.message.length > 0 || message.content.fileUrl) &&
                                    <div className={`p-3 rounded-sm min-h-12 bg-blue-200 ml-auto w-fit max-w-[calc(100%-24px)] relative group`}>
                                        {message.content.fileUrl && message.content.fileUrl.length > 0 && <img className="w-auto h-[125px] mb-3 rounded-sm object-contain ml-auto" src={message.content.fileUrl} alt="Uploaded Image" />}
                                        <ReactMarkdown components={customComponents}>{message.content.message}</ReactMarkdown>
                                        <ListenButton message={message} index={index} showButton={index < messages.length - 2} handlePlay={() => handlePlay(message.content.message, index)} handleStop={handleStop} isPlaying={playingMessageIndex === index} />
                                    </div>
                                ) : message.role === "ai" ? (
                                    (message.content.message.length > 0) &&
                                    <div className={`p-3 rounded-sm min-h-12 bg-gray-200 mr-auto w-fit max-w-[calc(100%-24px)] relative group`}>
                                        <ReactMarkdown components={customComponents}>{message.content.message}</ReactMarkdown>
                                        <ListenButton message={message} index={index} showButton={index < messages.length - 2} handlePlay={() => handlePlay(message.content.message, index)} handleStop={handleStop} isPlaying={playingMessageIndex === index} />
                                    </div>
                                ) : message.role === "tool" ? (
                                    // a sticker exists
                                    message.tool && functionMap[message.tool.name] && functionMap[message.tool.name].sticker &&
                                    // <Tooltip content="Open on the right" position="top">
                                        <div className="py-2" onClick={() => {
                                            // Mixpanel tracking for Tool Call Sticker Clicked
                                            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                                                mixpanel.track('Tool Call Revisited', {
                                                    $email: auth.state.data.email,
                                                    ftool: message.tool?.name,
                                                    content: message.tool?.content
                                                });
                                            }
                                        }}>
                                            {functionMap[message.tool.name].sticker!(message.tool?.version!, message.tool.content)}
                                        </div>
                                    // </Tooltip>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                    )
                }
            }
            )}
            <div ref={chatEndRef} className="h-12" />
        </div >
    )
}

export default ChatHistory;

interface ListenButtonProps {
    message: Message
    index: number;
    showButton: boolean;
    handlePlay: (message: string) => Promise<void>;
    handleStop: () => void;
    isPlaying: boolean;
}

const ListenButton: React.FC<ListenButtonProps> = ({ message, index, showButton, handlePlay, handleStop, isPlaying }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const threadId = useSelector((state: any) => state.session.data.thread?.thread_id);

    const onClickPlay = async () => {
        if (isPlaying) {
            handleStop();
        } else {
            setIsLoading(true);
            try {
                await handlePlay(message.content.message);
                if (threadId) {
                    await dispatch(AssistantThunks.incrementTimesListenedThunk(threadId));
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const buttonContent = (
        <div className="flex flex-row justify-center items-center gap-x-1">
            {isLoading ? (
                <svg className="animate-spin responsive-icon text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            ) : (
                <>
                    {isPlaying ? <Icon.PauseCircle className="responsive-icon" /> : <Icon.PlayCircle className="responsive-icon" />}
                    {!showButton && <p className="responsive-text">{isPlaying ? "Stop" : "Listen"}</p>}
                </>
            )}
        </div>
    );

    if (showButton) {
        return (
            <div className={`flex ${message.role === "human" ? "left-[-40px]" : "right-[-40px]"} absolute top-0 mt-1 opacity-0 group-hover:opacity-100 transition-opacity z-50`}>
                <Tooltip content={isPlaying ? "Stop Listening" : "Read to me"} position="top">
                    <IconButton
                        icon={isPlaying ? <Icon.PauseCircle className="responsive-icon" /> : <Icon.PlayCircle className="responsive-icon" />}
                        onClick={onClickPlay}
                        className="py-1"
                        variant="tertiary"
                        disabled={isLoading}
                    >
                        {buttonContent}
                    </IconButton>
                </Tooltip>
            </div>
        );
    } else {
        return (
            <div className={`flex ${message.role === "human" ? "justify-start" : "justify-end"} mt-2`}>
                <Tooltip content={isPlaying ? "Stop Listening" : "Read to me"} position="top">
                    <IconButton
                        icon={isPlaying ? <Icon.PauseCircle className="responsive-icon" /> : <Icon.PlayCircle className="responsive-icon" />}
                        onClick={onClickPlay}
                        className="py-1"
                        disabled={isLoading}
                        variant="tertiary"
                        size="xs"
                        text={isPlaying ? "Stop" : "Listen"}
                    >
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
};