import { PropsWithChildren, useEffect, useCallback } from "react"
import { Client } from "@langchain/langgraph-sdk";
import { useSession } from "../components/ducks/session/export";
import { useAssistant } from "../components/ducks/assistant/export";
import { useProject, useProjectInitialized } from "../components/ducks/project/export";
import { useSteps } from "../components/ducks/steps/export";
import LoadingPage from "../../pages/Loading/LoadingPage";
import { useUser } from "../components/ducks/user/export";
import React from "react";
import { useAuth } from "../components/ducks/auth/export";
import { useModal } from "../components/ducks/modal/export";

export const client = new Client({
    apiUrl: process.env.REACT_APP_LANGGRAPH_API_URL,
    defaultHeaders: {
        'x-api-key': process.env.REACT_APP_LANGSMITH_API_KEY
    }
});

const PathFinderInit = React.memo((props: PropsWithChildren) => {
    const session = useSession();
    const auth = useAuth();
    const modal = useModal();
    const { initialized } = useProjectInitialized();

    useEffect(() => {
        session.actions.setLoading(true)
        logger.log("[PATHFINDER INIT] 🎬 Session thread: ", session.data.thread, auth.state.data)
        if (session.data.thread) {
            if (auth.state.data.email) {
                session.thunks.doWhenThread()
            } else {
                modal.actions.setState('login')
            }
        } else {
            session.thunks.doWhenNoThread()
        }
        session.thunks.finishUp()
        session.actions.setLoading(false)
    }, [session.data.thread?.thread_id, auth.state.data.email]);

    if (initialized) {
        return <>{props.children}</>;
    } else {
        return <LoadingPage label={"Fetching Project.."} />
    }
})

export default PathFinderInit