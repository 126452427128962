import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { toastSlice } from "../components/ducks/toast/slice";
import { sessionSlice } from "../components/ducks/session/slice";
import { assistantSlice } from "../components/ducks/assistant/slice";
import { panelSlice } from "../components/ducks/panel/slice";
import { projectImagesSlice } from "../components/ducks/project/images/slice";
import { projectResourcesSlice } from "../components/ducks/project/resources/slice";
import { widgetSlice } from "../components/ducks/widget/slice";
import { authSlice } from "../components/ducks/auth/slice";
import { userSlice } from "../components/ducks/user/slice";
import { projectUploadSlice } from "../components/ducks/project/progress-upload/slice";
import { projectSlice } from "../components/ducks/project/slice"; // Added projectSlice
import { projectInspirationsSlice } from "../components/ducks/project/inspirations/slice";
import { optionsSlice } from "../components/ducks/options/slice";
import { stepsSlice } from "../components/ducks/steps/slice";
import { modalSlice } from "../components/ducks/modal/slice";
import { cartesiaSlice } from "../components/ducks/cartesia/slice";
import { socraticSlice } from "../components/ducks/socratic/slice";
import { screenShareSlice } from "../components/ducks/screenshare/slice";
import { voiceSlice } from '../components/ducks/voice/slice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['session', 'socratic'],
};

const rootReducer = combineReducers({
    // main dependencies -> a lot will break by removing these
    session: sessionSlice.reducer,
    toast: toastSlice.reducer,
    panel: panelSlice.reducer,
    widget: widgetSlice.reducer,
    modal: modalSlice.reducer,
    // ai
    assistant: assistantSlice.reducer,
    // project
    project: projectSlice.reducer,
    images: projectImagesSlice.reducer,
    resources: projectResourcesSlice.reducer,
    auth: authSlice.reducer,
    user: userSlice.reducer,
    upload: projectUploadSlice.reducer,
    inspirations: projectInspirationsSlice.reducer,
    options: optionsSlice.reducer,
    steps: stepsSlice.reducer,
    cartesia: cartesiaSlice.reducer,
    socratic: socraticSlice.reducer,
    screenShare: screenShareSlice.reducer,
    voice: voiceSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// setup hooks

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
