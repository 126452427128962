import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { assistantSlice } from "./slice";
import { AssistantThunks } from "./thunks";
import { useMemo } from "react";

// Create selectors
const selectAssistantLoading = (state: RootState) => state.assistant.loading;
const selectAssistantData = (state: RootState) => state.assistant.data;
const selectMessages = (state: RootState) => state.assistant.messages;
const selectAssistantSuggestions = (state: RootState) => state.assistant.suggestions;
const selectAssistantFileStatus = (state: RootState) => state.assistant.fileStatus;
// Add more selectors as needed

export const useMessages = () => {
    const dispatch = useDispatch();

    const messages = useSelector(selectMessages);

    return { messages }

}

export const useAssistant = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const loading = useSelector(selectAssistantLoading);
    const data = useSelector(selectAssistantData);
    const suggestions = useSelector(selectAssistantSuggestions)
    const fileStatus = useSelector(selectAssistantFileStatus);
    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(assistantSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(AssistantThunks, dispatch),
        [dispatch]
    );

    return {
        loading,
        data,
        fileStatus,
        suggestions,
        actions,
        thunks
    };
};