import ChatHistory from "../../utils/components/ducks/assistant/components/chat/History"
import ChatInput from "../../utils/components/ducks/assistant/components/chat/Input"
import { Widget } from "../../utils/components/ducks/widget/components/Widget"
import Suggestion from "../../utils/components/ducks/assistant/components/chat/Suggestion"
import { useAssistant, useMessages } from "../../utils/components/ducks/assistant/export"
import TitleHeader from "../../utils/components/ducks/user/components/TitleHeader"
import { Panel } from "../../utils/components/ducks/panel/components/Panel"
import UploadConfetti from "../../utils/components/ducks/project/progress-upload/components/UploadConfetti"
import { useWidget } from "../../utils/components/ducks/widget/export"
import { useScreenShare } from "../../utils/components/ducks/screenshare/export"


const ChatPage = () => {
    const { messages } = useMessages()
    const { suggestions } = useAssistant()
    const widget = useWidget()
    const { isScreenSharing } = useScreenShare()

    return (
        <>
            <main className={`relative flex flex-grow justify-center overflow-hidden px-2 sm:pl-8 sm:pr-4 pb-4 pt-4 gap-x-8 xl:pl-16 xl:pr-8 xl:pb-8`}>
                {messages.length === 0 && (
                    <div className="absolute inset-0"></div>
                )}
                <UploadConfetti />
                {/* Main content area */}
                <div className="flex-grow flex flex-col w-full md:max-w-[80%] lg:max-w-[66%] xl:max-w-[50%] items-stretch justify-center relative z-10 transition-all duration-300 ease-in-out">
                    {/* Chat content area */}
                    {messages.length > 0 ?
                        <ChatHistory />
                        :
                        <TitleHeader />
                    }

                    {/* Widgets */}
                    <Widget />

                    {/* Blue box at the bottom left */}
                    <div
                        className="flex bg-white border-gray-100 border-4 rounded flex-col z-20"
                    >
                        {widget.state === "none" && (
                            <div className={`transition-all duration-300 ease-in-out ${suggestions && suggestions.length > 0 ? 'py-2 xl:py-2 max-h-[300px]' : 'py-0 max-h-0'} gap-y-2 px-2 xl:px-2 flex flex-col overflow-hidden`}>
                                {Array.isArray(suggestions) && suggestions.length > 0 && suggestions.map((suggestion) => (
                                    <Suggestion key={suggestion} suggestion={suggestion} />
                                ))}
                            </div>
                        )}
                        <ChatInput 
                            size={
                                window.innerWidth < 640 ? 'sm' :
                                window.innerWidth < 768 ? 'md' :
                                'lg'
                            }
                            showTellMode={window.innerWidth >= 640}
                        />
                        {isScreenSharing && (
                            <div className="text-md text-black mt-2 py-2 text-center">
                                Pathfinder can see what you are screensharing, feel free to ask questions about it!
                            </div>
                        )}
                    </div>
                </div>
                {/* Red panel on the right */}
                {window.innerWidth >= 1024 && <Panel />}
            </main>
        </>
    )

}

export default ChatPage