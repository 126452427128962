import React, { useState, useCallback } from 'react';
import './tooltip.css';

interface TooltipProps {
  content: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactNode;
  className?: string;
  // Add a new prop to disable the tooltip
  disabled?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ 
  content, 
  position = 'top', 
  children, 
  className = '',
  disabled = false 
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (!disabled) {
      setIsVisible(true);
    }
  }, [disabled]);

  const handleMouseLeave = useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <div 
      className={`tooltip-container ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isVisible && !disabled && (
        <div className={`tooltip tooltip-${position}`}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;