import { Thunk } from "../utils";
import { toastSlice } from "../toast/slice";
import { CartesiaAPI } from "./api";
import { cartesiaSlice } from "./slice";

export namespace CartesiaThunks {
    export const generateSpeechThunk: Thunk<string, HTMLAudioElement> =
    (message: string) =>
        async (dispatch, getState) => {
            logger.log("[CARTESIA THUNK] 🎬 generate speech");

            try {
                dispatch(cartesiaSlice.actions.setLoading(true));
                const response = await CartesiaAPI.generateSpeech(message, dispatch);
                
                dispatch(cartesiaSlice.actions.setAudioData(response));

                const stream = new ReadableStream({
                    start(controller) {
                        controller.enqueue(response);
                        controller.close();
                    }
                });
                const audio = await playAudioFromStream(stream);
                return audio;
            } catch (error: any) {
                logger.log("[CARTESIA THUNK] 🔴 generate speech:", error);
                dispatch(toastSlice.actions.setToast({
                    type: "error",
                    message: "Failed to generate speech. Please try again.",
                    error: error.message || "Unknown error occurred",
                    fatal: false
                }));
                throw error; // Re-throw the error instead of returning undefined
            } finally {
                dispatch(cartesiaSlice.actions.setLoading(false));
                logger.log("[CARTESIA THUNK] 🔚 generate speech complete");
            }
        };

    async function playAudioFromStream(stream: ReadableStream) {
        const reader = stream.getReader();
        const chunks = [];
      
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          chunks.push(value);
        }
      
        const blob = new Blob(chunks, { type: 'audio/mpeg' });
        const audio = new Audio();
        audio.src = URL.createObjectURL(blob);
        await audio.play();
        return audio;
    }
}