import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { sessionSlice } from "./slice";
import { SessionThunks } from "./thunks";
import { useMemo } from "react";

// Create selectors
const selectSessionLoading = (state: RootState) => state.session.loading;
const selectSessionData = (state: RootState) => state.session.data;
const selectSessionView = (state: RootState) => state.session.view;

export const useSession = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const loading = useSelector(selectSessionLoading);
    const data = useSelector(selectSessionData);
    const view = useSelector(selectSessionView);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(sessionSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(SessionThunks, dispatch),
        [dispatch]
    );

    return {
        loading,
        view,
        data,
        actions,
        thunks
    };
};