import { store } from "../../../../init/reduxInit";
import { isSignedIn } from "../../auth/export";
import { initState } from "../../auth/state";
import { panelSlice } from "../../panel/slice";
import { toastSlice } from "../../toast/slice";
import { Thunk } from "../../utils";
import { widgetSlice } from "../../widget/slice";
import { API } from "./api";
import { projectResourcesSlice } from "./slice";
import { Resource, ResourcePage, Resources } from "./state";

export namespace ProjectResourcesThunks {

    export const addPage: Thunk<ResourcePage> = (args: any) => async (dispatch, getState) => {
        try {
            logger.log("[PROJECT RESOURCES THUNK] 🔵 Add page operation started");
            // dispatch(panelSlice.actions.setState('resources'));
            // dispatch(widgetSlice.actions.setState('resources'));
            const parsedArgs = JSON.parse(args)

            if (Object.keys(parsedArgs).length > 0) {
                // this is a plaster for replenishing state, because the new resource page is made before resources are found.
                if (getState().resources.resourcePages.length > 0 && getState().resources.resourcePages[0].extraResources?.length > 0) {
                    dispatch(projectResourcesSlice.actions.addResourcePage());
                }

                const { title, description, resources } = parsedArgs
                logger.log(`Found ${resources.videos.length} videos and ${resources.articles.length} articles`)

                const extraResources: Resources = {
                    videos: resources.videos.map((video: any) => ({
                        title: video.title,
                        url: video.url,
                        content: video.content,
                        score: video.score,
                        type: "video"
                    })),
                    articles: resources.articles.map((article: any) => ({
                        title: article.title,
                        url: article.url,
                        content: article.content,
                        score: article.score,
                        type: "article"
                    }))
                };

                const page: ResourcePage = {
                    title: title,
                    description: description,
                    mainResource: null,
                    extraResources: [extraResources]
                };
                dispatch(projectResourcesSlice.actions.addResources(page));
                dispatch(projectResourcesSlice.actions.setMainResource(extraResources.videos[0]));
                dispatch(projectResourcesSlice.actions.setLoading(false));

                dispatch(saveResourcePageThunk());

            } else {
                dispatch(projectResourcesSlice.actions.addResourcePage());
                dispatch(projectResourcesSlice.actions.setLoading(true));
            }
        } catch (error: any) {
            logger.log("[PROJECT RESOURCES THUNK] 🔴 Error adding page", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to add the resource page.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
            dispatch(panelSlice.actions.reset());
            dispatch(widgetSlice.actions.reset());
        } finally {
            logger.log("[PROJECT RESOURCES THUNK] 🟢 Add page complete");
        }
    }

    export const saveResourcePageThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[PROJECT THUNK] 🟣 Saving resource page");

        try {
            if (isSignedIn(getState)) {
                const response = await API.saveResourcePage({
                    threadId: getState().session.data.thread!.thread_id,
                    userEmail: getState().auth.data.email,
                    resourcePageNumber: getState().resources.resourcePages.length,
                    resourcePage: getState().resources.resourcePages[0]
                });

                if (response.status !== 200) {
                    throw new Error(response.data);
                }
            }

        } catch (error: any) {
            logger.error("[PROJECT THUNK] 🔴 Error saving resource page", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to save resource page",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[PROJECT THUNK] 🟢 Save resource page completed");
        }
    };

    export const setPageThunk: Thunk<number> = (pageNumber: number) => async (dispatch, getState) => {
        dispatch(projectResourcesSlice.actions.setPage(pageNumber));
    };

}