import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VoiceState, initState, ConnectionDetails, ExtendedTranscriptionSegment } from "./state";
import { AgentState } from "@livekit/components-react";

export const voiceSlice = createSlice({
    initialState: initState,
    name: "voice",
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setError(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        setAudioData(state, action: PayloadAction<any>) {
            state.data.audioData = action.payload;
        },
        setConnectionDetails(state, action: PayloadAction<ConnectionDetails | undefined>) {
            state.data.connectionDetails = action.payload || null;
        },
        setAgentState(state, action: PayloadAction<AgentState>) {
            state.data.agentState = action.payload;
        },
        updateTranscriptions(state, action: PayloadAction<ExtendedTranscriptionSegment[]>) {
            action.payload.forEach(segment => {
                state.data.transcriptions[segment.id] = segment;
            });
        },
        setCurrentThreadId(state, action: PayloadAction<number | null>) {
            state.data.currentThreadId = action.payload;
        },
        loadPreviousMessages(state, action: PayloadAction<ExtendedTranscriptionSegment[]>) {
            state.data.transcriptions = {}; // Clear current transcriptions
            state.data.loadedMessages = action.payload;
        },
        reset() {
            return initState;
        },
    },
});

export const { setLoading, setError, setAudioData, setConnectionDetails, setAgentState, updateTranscriptions, loadPreviousMessages, reset } = voiceSlice.actions;
export default voiceSlice.reducer;
