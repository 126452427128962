import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../../index"
import { ProjectFile, ProjectUploadState, initState } from "./state";
import { Step } from "../../steps/state";

export const projectUploadSlice = createSlice({
    initialState: initState,
    name: "upload",
    reducers: {

        setStatus(state, action: Action<ProjectUploadState['status']>) {
            return {
                ...state,
                status: action.payload,
            }
        },

        reset(state) {
            return {
                ...state,
                ...initState
            }
        }

    }
});