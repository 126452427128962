import React, { useState, useCallback, useEffect } from 'react';
import { useUpload } from '../export';
import * as Icons from 'solar-icon-set'
import Button from '../../../../grapes/atoms/button/Button';

const ProgressUploadWidget: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const handleFileChange = useCallback((file: File) => {
        setSelectedFile(file);
        if (file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null);
        }
    }, []);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            handleFileChange(event.dataTransfer.files[0]);
        }
    }, [handleFileChange]);

    return (
        <div className="px-2 flex flex-col items-center h-full overflow-y-hidden justify-end w-full space-y-6">
            <h2>{"Show me what you've done!"}</h2>
            <div
                className={`border-2 w-full border-dashed rounded-md p-8 text-center ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                    }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {!selectedFile ? (
                    <div className='space-y-4 flex flex-col justify-center items-center'>
                        <p>Drag and drop a file here, or</p>
                        <input
                            type="file"
                            id="fileInput"
                            className="hidden"
                            onChange={(e) => e.target.files && handleFileChange(e.target.files[0])}
                        />
                        <label htmlFor="fileInput">
                            <Button
                                variant="primary"
                                className='w-full'
                                onClick={() => document.getElementById('fileInput')?.click()}
                            >
                                <p>Select a file</p>
                            </Button>
                        </label>
                    </div>
                ) : (
                    <div className="flex flex-col items-center space-y-4">
                        {previewUrl ? (
                            <img src={previewUrl} alt="File preview" className="max-w-full h-auto max-h-48 rounded-md" />
                        ) : (
                            <div className="flex items-center justify-center">
                                {selectedFile.type.startsWith('video/')
                                    ?
                                    <Icons.ClapperboardOpen size={64} className="text-blue-500" />
                                    :
                                    <Icons.File size={64} className="text-blue-500" />}
                            </div>
                        )}
                        <span className="font-medium">{selectedFile.name}</span>
                        <UploadButton selectedFile={selectedFile} />
                    </div>
                )}
            </div>

        </div>
    );
};

export default ProgressUploadWidget;


const UploadButton = (props: { selectedFile: File | null }) => {
    const { thunks, status } = useUpload();

    const handleUploadClick = () => {
        if (props.selectedFile) {
            thunks.uploadStepFileThunk({ file: props.selectedFile });
        }
    };

    return (
        <>
            {props.selectedFile && <Button
                variant='primary'
                onClick={handleUploadClick}
                disabled={status !== "ok"}
                className="mt-4 w-full"
            >
                <p>{`${status !== 'ok' ? `${status!.charAt(0).toUpperCase() + status!.slice(1)}...` : 'Upload file'}`}</p>
            </Button>
            }
        </>
    );
};

