import React, { useState, useEffect } from 'react';
import Button from '../../../../../grapes/atoms/button/Button';
import * as Icons from 'solar-icon-set';
import { useToast } from '../../../../../ducks/toast/export';
import IconButton from '../../../../../grapes/atoms/iconButton/IconButton';

interface AdditionalInstructionsInputProps {
    instructionTitle: string;
    instructionDescription: string;
    placeholderText: string;
    currentInstructions: string;
    onSubmit: (prompt: string) => void;
    isHorizontal?: boolean;
}

const AdditionalInstructionsInput: React.FC<AdditionalInstructionsInputProps> = ({
    instructionTitle,
    instructionDescription,
    placeholderText,
    currentInstructions,
    onSubmit,
    isHorizontal = false
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const toast = useToast(); // Use your toast hook

    useEffect(() => {
        if (isLoading && toast.state.toast === toast.initState.toast) {
            setIsLoading(false);
        }
    }, [toast.state.toast, isLoading]);

    useEffect(() => {
        logger.log('isLoading:', isLoading);
    }, [isLoading]);

    useEffect(() => {
        setInputValue(currentInstructions);
    }, [currentInstructions]);

    const handleSubmit = async () => {
        setIsLoading(true);
        await onSubmit(inputValue);
        setIsLoading(false);
        setIsSent(true);
        setTimeout(() => {
            setIsSent(false);
        }, 3000);
    };

    return (
        <div className={`flex ${isHorizontal ? 'flex-row' : 'flex-col'} w-full space-x-2`}>
            <div className={`flex flex-col ${isHorizontal ? 'mr-4' : 'mb-2'}`}>
                <h3 className="text-lg font-bold text-black dark:text-gray-200 mb-1">
                    {instructionTitle}
                </h3>
                <p className="text-xs text-gray-500 dark:text-gray-400 mb-2">
                    {instructionDescription}
                </p>
            </div>
            <div className="flex-grow w-full relative">
                <textarea
                    id="additionalInstructions"
                    className={`guide-text-area w-full ${!isHorizontal ? 'ml-[-0.5rem]' : ''} px-3 py-2 text-sm text-black h-full`}
                    placeholder={placeholderText}
                    rows={4}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <IconButton
                    variant={isLoading || isSent || (currentInstructions === inputValue) ? "secondary" : "primary"}
                    onClick={handleSubmit}
                    className={`absolute bottom-1 ${!isHorizontal ? 'right-3' : 'right-1'}`}
                    disabled={isLoading || isSent}
                    size="sm"
                    icon={
                        isLoading ? (
                            <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : (isSent ? <Icons.CheckCircle /> : <Icons.ArrowRight />)
                    }
                />
            </div>
        </div>
    );
};

export default AdditionalInstructionsInput;