import React from 'react';
import { usePanel } from '../../../ducks/panel/export';
import { useWidget } from '../../../ducks/widget/export';
import { useAssistant } from '../../../ducks/assistant/export';
import { functionMap } from '../../../ducks/functions';
import { RootState } from '../../../../init/reduxInit';
import Tooltip from '../../atoms/tooltip/Tooltip';


interface StickerProps {
    icon: React.ComponentType<any>;
    friendly_name: string;
    toolVersion?: number;
    version?: boolean,
    toolName?: string,
    showMessage?: boolean;
    state?: string
}

const Sticker: React.FC<StickerProps> = ({ state, icon, friendly_name, version, toolVersion, showMessage = false }) => {
    const panel = usePanel()
    const widget = useWidget()
    const assistant = useAssistant()

    const handleClick = () => {
        // @ts-ignore
        panel.actions.setState(state);
        // @ts-ignore
        widget.actions.setState(state);

        if (toolVersion) {
            assistant.thunks.runFunction(functionMap["searchVideoAndArticleResources"].versioning!.set(toolVersion!))
        }
    };
    return (
        <Tooltip content="Click to open" position="top">
            <button
                className={`inline-flex items-center gap-x-2 border border-black rounded py-2 px-4 bg-white hover:bg-gray-100 ${showMessage ? 'mt-2' : ''}`}
                onClick={handleClick}
            >
                {React.createElement(icon)}
                <p>
                    {friendly_name}
                </p>
            </button>
        </Tooltip>
        // <p>{`Version: ${toolVersion}`}</p>
    );
};

export default Sticker;
