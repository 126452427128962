import React from 'react';
import * as Icons from 'solar-icon-set'
import { useAssistant } from '../../../assistant/export';
import { functionMap } from '../../../functions';
import { useWidget } from '../../../widget/export';
import { usePanel } from '../../../panel/export';


interface StickerProps {
    toolVersion?: number;
}

const Sticker: React.FC<StickerProps> = ({ toolVersion }) => {
    const panel = usePanel()
    const widget = useWidget()
    const assistant = useAssistant()

    const handleClick = () => {
        // @ts-ignore
        panel.actions.setState("resources");
        // @ts-ignore
        widget.actions.setState("resources");

        if (toolVersion) {
            assistant.thunks.runFunction(functionMap["searchVideoAndArticleResources"].versioning!.set(toolVersion!))
        }
    };

    return (
        <button
            className={`mb-4 inline-flex items-center gap-x-2 border border-black rounded py-2 px-4 bg-white hover:bg-gray-100`}
            onClick={handleClick}
        >
            {React.createElement(Icons.BookBookmark)}
            <p>
                {"Generated cool resources"}
            </p>
        </button>
        // <p>{`Version: ${toolVersion}`}</p>
    );
};

export default Sticker;

