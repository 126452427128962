import React from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../../../grapes/atoms/tooltip/Tooltip';
import Button from '../../../../grapes/atoms/button/Button';
import { useUser } from '../../export';

const GuideDashboardButton: React.FC = () => {
    const user = useUser();
    const navigate = useNavigate();

    return (
        <div className="flex items-center justify-center">
            {(user.state.data.role === 'Teacher' || user.state.data.role === 'Guide' || user.state.data.role === 'Parent') && (
                <Tooltip content="Go to Dashboard" position="bottom">
                    <Button
                        variant="primary"
                        label="Dashboard"
                        onClick={() => navigate('/guide-dashboard')}
                        useResponsiveSize
                    >
                        Dashboard
                    </Button>
                </Tooltip>
            )}
        </div>
    );
};

export default GuideDashboardButton;
