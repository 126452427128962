import React, { useState, useEffect } from "react";
import AuthButton from "../../../ducks/auth/components/authButton";
import pfLogo from "../../../../../assets/images/pf-icon.svg";
import { useAuth } from "../../../ducks/auth/export";
import { VoiceAPI } from "../api";
import { useVoice } from "../export";
import Dropdown, { DropdownOption } from "../../../grapes/molecules/dropdown/Dropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../../init/reduxInit";

type VoiceThread = {
  id: number;
  title: string;
  updatedAt: string;
};

type VoiceMessage = {
  id: number;
  text: string;
  isAI: boolean;
  createdAt: string;
};

const VoiceNavbar: React.FC = () => {   
  const { thunks, actions } = useVoice();
  const auth = useAuth();
  const [threads, setThreads] = useState<VoiceThread[]>([]);
  const currentThreadId = useSelector((state: RootState) => state.voice.data.currentThreadId);

  const fetchThreads = async () => {
    if (auth.state.data.email) {
      try {
        const response = await VoiceAPI.getVoiceThreads(auth.state.data.email);
        setThreads(response.threads);
      } catch (error) {
        console.error("Error fetching voice threads:", error);
      }
    }
  };

  useEffect(() => {
    fetchThreads();
  }, [auth.state.data.email]);

  // Add this new useEffect to watch for currentThreadId changes
  useEffect(() => {
    if (currentThreadId) {
      fetchThreads();
    }
  }, [currentThreadId]);

  const handleThreadClick = async (threadId: number) => {
    try {
      const response = await VoiceAPI.getVoiceMessages(threadId);
      const messages = response.messages;
      
      const transcriptionSegments = messages.map((msg: VoiceMessage) => ({
        id: msg.id.toString(),
        participantId: msg.isAI ? "ai" : "user",
        text: msg.text,
        firstReceivedTime: new Date(msg.createdAt).getTime(),
        language: "en"
      }));

      await thunks.createRoomAndToken(threadId);
      actions.setCurrentThreadId(threadId);
      actions.loadPreviousMessages(transcriptionSegments);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const dropdownOptions: DropdownOption[] = threads.map((thread) => ({
    value: thread.id.toString(),
    label: thread.title,
    onClick: () => handleThreadClick(thread.id)
  }));

  return (
    <nav className="flex items-center px-4 bg-white shadow-sm min-h-[55px]">
      <div className="flex justify-start items-center space-x-4">
        <img src={pfLogo} alt="Logo" className="w-6 h-6 md:w-8 md:h-8" />
        
        <div className="w-64">
          <Dropdown
            options={dropdownOptions}
            placeholder="Previous Conversations"
            multiSelect={false}
            showSearch={true}
            useResponsiveSize={true}
            className="custom-dropdown-class"
          />
        </div>
      </div>

      <div className="flex flex-grow justify-end items-center">
        <AuthButton />
      </div>
    </nav>
  );
};

export default VoiceNavbar;